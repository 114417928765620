
const error = function (...msg) {
    console.error(msg);
    if (window.location.hostname != "localhost")
        gtag('event', 'exception', {
            'description': msg,
            'fatal': false
        });
    // throw (msg);
}

window.onerror = function (message, file, line, col, err) {
    error(err.message);
    return false;
};


class Mutex {
    constructor() {
        this.promise = Promise.resolve();
    }

    runExclusive(callback, ...args) {

        return (this.promise = this.promise.then(async () => {
            try {
                const ret = await callback(...args);
                // console.log(ret);
            } catch (e) {
                console.error(e);
            }
            return;
        }));
    }
}

window.addEventListener('beforeunload', function (event) {
    // debugger;
    console.trace('beforeunload stack trace:');
    console.log("beforeunload", event);
});


// window.addEventListener("error", function (e) {
//     error(e.error.message);
//     return false;
// })

const setStoredJSON = function (key, object, storage) {
    try {
        storage ? storage : storage = localStorage;
        if (object) {
            storage.setItem(key, JSON.stringify(object));
        } else {
            storage.removeItem(key);
        }
    } catch (e) { console.error(e); };
}

const getStoredJSON = function (key, storage) {
    try {
        storage ? storage : storage = localStorage;
        const item = storage.getItem(key);
        if (item) {
            const object = JSON.parse(item);
            return object;
        }
    } catch (e) { };
    return undefined
}

const getStoredJSONArray = function (key, storage) {
    try {
        const array = getStoredJSON(key, storage);
        if (!array) {
            array = [];
        }
        return array;
    } catch (e) { return [] };
}

const getStoredJSONObject = function (key, storage, init) {
    try {
        const obj = getStoredJSON(key, storage);
        if (!obj) {
            obj = init ? init : {};
        }
        return obj;
    } catch (e) { return init ? init : {} };
}

const proxyStored = function (property, init, parent, storage) {
    parent = parent || window;
    Object.defineProperty(parent, property, {
        get() {
            return getStoredJSONObject(property, storage || localStorage, init);
        },
        set(obj) {
            // const obj = parent[property];
            return setStoredJSON(property, obj, storage || localStorage);
        }
    });
}

proxyStored("milestones", [
    {
        "id": "vipmatch",
        "progress": 3,
        "level": 1,
    },
    {
        "id": "competence",
        "progress": 0,
        "level": 1
    },
    {
        "id": "delegate-competence",
        "progress": 0,
        "level": 1
    }
]);

proxyStored("lastVoted", []);


const getMilestone = (id) => {
    for (let i = 0; i < window.milestones.length; i++) {
        const milestone = window.milestones[i];
        if (milestone.id == id) {
            return milestone;
        }
    }
    return null;
}

Object.defineProperty(window, "preview", {
    get() {
        const url = new URL(window.location.href);
        if (url.searchParams.get("preview")) {
            return true;
        } else {
            return false;
        }
    }
});

document.addEventListener("voted", async (e) => {
    const dialectic = e.detail.dialectic;
    const statement = e.detail.statement;

    const lastVoted = window.lastVoted;
    var lastVotedMap = lastVoted.reduce(function (map, obj) {
        map[obj.dialectic.id] = obj;
        return map;
    }, {});

    const vote = { dialectic: { lastUpdated: dialectic.lastUpdated, id: dialectic.id }, voted: statement };
    lastVotedMap[dialectic.id] = vote;
    let vv = Object.values(lastVotedMap);
    vv = vv.sort((a, b) => {
        return a.dialectic.lastUpdated - b.dialectic.lastUpdated;
    });
    vv = vv.filter((item, index) => index < 10);
    window.lastVoted = vv;

    // onVoted(vote, lastVoted);
}, true);


const handleUserActionResponse = (userActionResponse) => {
    if (userActionResponse.milestones && userActionResponse.milestones.length > 0) {
        const event = new CustomEvent("milestone_progress", {
            detail: {
                milestones: userActionResponse.milestones
            },
        });
        document.dispatchEvent(event);
    }
}

function uniqueArray(arr, compareFn) {
    const seen = new Set();
    const uniqueArray = [];

    for (const element of arr) {
        if (element) {
            const compareValue = compareFn(element);
            if (compareValue && !seen.has(compareValue)) {
                seen.add(compareValue);
                uniqueArray.push(element);
            }
        }
    }

    return uniqueArray;
}

const unique = function (array, key) {
    var result = array.reduce(function (map, item) {
        map[item[key]] = item;
        return map;
    }, {});
    return Object.values(result);
}

async function asyncForEach2(array, callback) {
    array = [...array];
    for await (const el of array) {
        await callback(el, array.indexOf(el), array);
    }
}

async function asyncForEach(array, callback) {
    const promises = [];
    for (let index = 0; index < array.length; index++) {
        const promise = callback(array[index], index, array);
        if (promise) {
            promise.catch((e) => {
                console.error(e);
            })
            promises.push(promise);
        }
    }

    await Promise.all(promises);

}


const debug = window.debug = window.location.hostname == "localhost" || getStoredJSONObject("config")["debug_mode"] || false;

const log = console.log;
// if (!window.debug) {
//     console.log = function () { }
// }
window.setDebug = function (debug) {
    setStoredJSON("config", { debug_mode: debug });
}


const apiResponseHandler = function (res) {
    return res.clone().json();
};

const htmlResponseHandler = async function (res) {
    const html = await res.clone().text()
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");
    return doc;
};

// Monkey patch fetch
if (typeof window.fetch === 'function') {
    const originalFetch = window.fetch;
    window.fetch = (...args) => {
        const url = args[0];
        const options = args[1];

        if (url.indexOf('google-analytics.com') > 0) {
            url = getApiHost() + "/event";
        }
        // Call the original fetch for other requests
        return originalFetch(...args);
    };
}
// Monkey patch sendBeacon (if supported)
if (typeof window.navigator.sendBeacon === 'function') {
    const originalSendBeacon = window.navigator.sendBeacon.bind(window.navigator);
    window.navigator.sendBeacon = (url, data) => {
        if (url.indexOf('google-analytics.com') > 0) {
            if (!data) {
                data = {};
            }
            data.url = url;
            data.sessionToken = getSessionToken();

            data = JSON.stringify(data);

            url = getApiHost() + "/event";
        }
        // Call the original sendBeacon for other requests
        return originalSendBeacon(url, data);

    };
}

// Monkey patch setCookie (use with caution and provide clear user consent)
Object.defineProperty(document, 'cookie', {
    writable: true,
    enumerable: true,
    configurable: true,
    value: {
        set(value) {
            // Store cookie data in localStorage
            const cookieParts = value.split(';');
            const cookieName = cookieParts[0].split('=')[0].trim();
            const cookieValue = cookieParts[0].split('=')[1].trim();
            localStorage.setItem(cookieName, cookieValue);

            // Call original setcookie for browser compatibility
            // originalSetCookie(value);
        },
        get() {
            return originalSetCookie;
        },
    },
});


const fetchStatic = async function (url) {

    // if (!window.my_cache) {
    //     await caches.open("CACHE").then((cache) => {
    //         window.my_cache = cache;
    //     });
    // }

    const response = null; // = await window.my_cache.match(url);
    if (response) {
        return htmlResponseHandler(response);
    } else {
        const response = await fetch(url)
            .then(async (res) => {
                if (res.status >= 300) {
                    if (res.status == 401) {
                        document.body.setAttribute("loggedin", "false");
                        needsLogin();
                    } else if (res.status >= 500) {
                        console.error(res.status);
                    }
                }
                // await window.my_cache.put(url, res.clone());
                return await htmlResponseHandler(res);
            }).catch((e) => {
                // console.error(e);
            });
        return response;
    }
}

const getSessionToken = () => {
    return localStorage.getItem("sessionToken");
}

const fetchAPI = window.fetchAPI = async function (url, options) {

    if (!options) {
        options = {};
    } else if (options && options.method != "GET" && !isLoggedIn()) {
        needsLogin();
        //do not do POST when not logged In
        return;
    }

    options.mode = "cors";
    options.credentials = "include";

    url = window.getApiHost() + url;

    const urlObject = new URL(url);
    // urlObject.searchParams.append("v", "0.1");
    url = urlObject.toString();

    // console.log("loading:", url, loadCounter.count);
    load();

    if (!document.controller)
        document.controller = new AbortController();
    const signal = document.controller.signal;
    options.signal = signal;

    const headers = options.headers;

    options.headers = new Headers();

    if (headers) {
        for (const [key, value] of Object.entries(headers)) {
            options.headers.set(key, value);
        }
    }
    let token;
    const user = getStoredJSON("user");
    if (user) {
        token = getSessionToken();
        options.headers.set("at", token);
    }
    const response = await fetch(url, options)
        .then(async (res) => {
            if (res.status >= 300) {
                if (res.status == 401) {
                    needsLogin();
                    throw ("401", token);
                } else if (res.status >= 500) {
                    console.error(res.status);
                }
            }
            window.setTimeout(() => {
                load(true);
            }, 666);
            return apiResponseHandler(res);
        }).catch((e) => {
            // console.error(e);
            load(true);
        });

    return response;
}

function createTagCloudFromElements(container) {
    const children = Array.from(container.children);

    if (children.length === 0) return;

    // Randomly position elements initially
    children.forEach(child => {
        child.style.left = `${Math.random() * container.offsetWidth}px`;
        child.style.top = `${Math.random() * container.offsetHeight}px`;
    });

    // Initialize Packery
    try {
        if (Packery) {
            const pckry = new Packery(container, {
                itemSelector: '.tag', // Select all direct children
                gutter: 3, // Adjust gutter as needed
                percentPosition: false, // Use pixel values for positioning
            });
        }
    } catch (e) {

    }
}

function createTagCloudFromElements2(container, options = {}) {
    const { centerOffsetX = 0, centerOffsetY = 0 } = options;

    const children = Array.from(container.children);
    if (children.length === 0) return;

    container.style.position = 'relative';

    let maxWidth = 0;
    let maxHeight = 0;
    let maxPadding = 0; // Find the largest padding among elements

    children.forEach(child => {
        const computedStyle = window.getComputedStyle(child);
        const width = child.offsetWidth + parseInt(computedStyle.marginLeft) + parseInt(computedStyle.marginRight);
        const height = child.offsetHeight + parseInt(computedStyle.marginTop) + parseInt(computedStyle.marginBottom);
        const paddingLeft = parseInt(computedStyle.paddingLeft) || 0;
        const paddingRight = parseInt(computedStyle.paddingRight) || 0;
        const paddingTop = parseInt(computedStyle.paddingTop) || 0;
        const paddingBottom = parseInt(computedStyle.paddingBottom) || 0;

        maxWidth = Math.max(maxWidth, width);
        maxHeight = Math.max(maxHeight, height);
        maxPadding = Math.max(maxPadding, paddingLeft, paddingRight, paddingTop, paddingBottom);
    });

    container.style.width = 'fit-content';
    container.style.height = 'fit-content';

    let containerWidth = container.offsetWidth;
    let containerHeight = container.offsetHeight;

    containerWidth = Math.max(containerWidth, maxWidth * 2);
    containerHeight = Math.max(containerHeight, maxHeight * 2);

    container.style.width = `${containerWidth}px`;
    container.style.height = `${containerHeight}px`;

    const centerX = containerWidth / 2 + centerOffsetX;
    const centerY = containerHeight / 2 + centerOffsetY;

    const placedElements = [];

    function checkOverlap(element, x, y) {
        const rect1 = element.getBoundingClientRect();

        for (const placed of placedElements) {
            const rect2 = placed.element.getBoundingClientRect();
            if (
                x < rect2.right &&
                x + rect1.width > rect2.left &&
                y < rect2.bottom &&
                y + rect1.height > rect2.top
            ) {
                return true;
            }
        }
        return false;
    }

    function placeElement(element) {
        element.style.position = 'absolute';
        const elementWidth = element.offsetWidth;
        const elementHeight = element.offsetHeight;

        let attempts = 0;
        const maxAttempts = 1000;

        while (attempts < maxAttempts) {
            const angle = Math.random() * 2 * Math.PI;
            const distance = Math.sqrt(Math.random()) * Math.min(containerWidth, containerHeight) / 2;

            const x = centerX + distance * Math.cos(angle) - elementWidth / 2;
            const y = centerY + distance * Math.sin(angle) - elementHeight / 2;

            if (
                x >= maxPadding &&
                x + elementWidth <= containerWidth - maxPadding &&
                y >= maxPadding &&
                y + elementHeight <= containerHeight - maxPadding &&
                !checkOverlap(element, x, y)
            ) {
                element.style.left = `${x}px`;
                element.style.top = `${y}px`;
                placedElements.push({ element, x, y });
                return;
            }
            attempts++;
        }

        element.style.left = `${centerX - elementWidth / 2}px`;
        element.style.top = `${centerY - elementHeight / 2}px`;
        placedElements.push({ element, x: centerX - elementWidth / 2, y: centerY - elementHeight / 2 });
    }

    children.forEach(placeElement);
}



class Navigator {

    constructor(container) {
        this.list = container.appendChild(document.createElement("div"));
        this.list.classList.add("list");
        this.bottom = container.appendChild(document.createElement("div"));
        this.bottom.classList.add("bottom-of-content");
        this.loader = this.bottom.appendChild(document.createElement("div"));
        this.loader.classList.add("loader");
        this.id = container.id;
    }

    view_index = -1;
    views = [];
    showUntil = 10;
    pending_action = undefined;

    reset() {
        if (document.controller && !window.preview) {
            console.error("reset");
            document.controller.abort();
        }
        this.list.innerHTML = "";
        this.list.setAttribute("class", "list");
        delete this.onLoadMore;
        this.showUntil = 10;
        this.view_index = -1;
        this.views = [];
        document.tabs.querySelectorAll(".tab").forEach(tab => {
            tab.setAttribute("active", false);
        });
    }

    async loadMore() {

        if (Viewer.oneView) {
            this.view_index++;
            if (this.view_index < this.views.length) {
                const view = this.views[this.view_index];
                await this.show(view);
                return;
            }
            return;
        }

        if (!isLoggedIn()) {
            needsLogin();
            return;
        }

        console.log("load more", this.showUntil, this.view_index, this.views.length);
        if (this.view_index >= containerNavigator.showUntil - 1) {
            containerNavigator.showUntil += 10;
        }
        if (this.view_index < this.views.length - 1) {

            if (Viewer.oneView) {
                const view = this.views[this.view_index];
                const viewIndex = view.viewIndex;
                if (viewIndex == this.view_index) {
                    await this.show(view);
                }
            } else {
                for (this.view_index; this.view_index < this.views.length && this.view_index < this.showUntil; this.view_index++) {
                    const view = this.views[this.view_index];
                    await this.show(view);
                }
            }

        } else
            if (this.onLoadMore) {
                await this.onLoadMore();
            }
    }

    getCurrentView() {
        return this.views[this.view_index];
    }

    async registerCurrentView(uristring, title) {
        const uri = {
            uri: uristring
        };
        const hash = containerNavigator.encodeHash(uri);
        const url = "/"; //"/#" + hash
        // history.pushState(uri, title, url);

        // document.title = title;

        // console.log("view registered", uristring, title);

        document.querySelector("title").textContent = title;

        await Sharer.register(uri);
    }

    onpopstate(event) {
        console.log(event);
        window.onhashchange();
    }

    removeView = (oldView) => {

        for (let i = 0; i < this.views.length; i++) {
            if (this.views[i].uri == oldView.uri) {
                this.views.splice(i, 1);
                if (oldView.attachedNode)
                    oldView.attachedNode.parentNode.removeChild(oldView.attachedNode);
                return;
            }
        }
        return null;
    }

    add2Views = async (view) => {

        try {

            if (view.then) {
                view = await view;
            }

            if (view.uri) {
                const oldView = this.getView(view.uri);
                if (oldView) {
                    console.log("replacing view:", oldView.uri);
                    this.removeView(oldView);
                }
            }

            view.list = this.list;
            view.containerNavigator = this;

            const viewIndex = this.views.push(view) - 1;
            view.viewIndex = viewIndex;

            const placeholder = document.createElement("placeholder");
            view.placeholder = placeholder;
            placeholder.view = view;
            placeholder.id = view.uri;

            this.list.appendChild(placeholder);

            // console.log("pushed view", view.viewIndex, view.uri, view);

            if (Viewer.oneView) {
                if (this.view_index == -1) {
                    this.view_index = 0;
                }

                if (viewIndex == this.view_index) {
                    const node = await this.show(view);
                    this.to(viewIndex);
                } else if (view.load) {
                    const loaded = await view.load();
                    view.loaded = loaded;
                }
            } else {
                if (this.view_index < this.showUntil) {
                    this.view_index++;
                    await this.show(view);
                }
            }
        } catch (e) {
            // if (view == undefined) {
            //     return;
            // }
            console.error(e);
        }
    }

    async show(view) {

        try {
            assert(view != undefined);

            const nview = await Viewer.show(view);
            if (view.attachedNode) {
                view.attachedNode.style.display = "";
                assert(nview);

                if (Viewer.oneView && this.view_index > 0) {
                    if (this.views[this.view_index - 1] && this.views[this.view_index - 1].attachedNode) {
                        this.views[this.view_index - 1].attachedNode.style.display = "none";
                    }
                }
            }
            return nview;
        } catch (e) {
            console.error("error with view: ", view.uri, e);
            e.view = view.uri;
            throw e;
        }
    }

    hasView(uri) {
        const view = this.getView(uri);
        return view != null;
    }

    refreshView(uri) {
        const view = containerNavigator.getView(uri);
        console.log("refresh view", view);
        if (view)
            Viewer.show(view);
    }

    getView(uri) {

        for (let i = 0; i < this.views.length; i++) {
            if (this.views[i].uri == uri) {
                return this.views[i];
            }
        }
        return null;
    }

    async createView(uri, data, list) {


        if (!list) {
            list = this.list;
        }

        const getData = async function () {
            if (!data) {
                const path = uriToPath(uri);
                if (uri.startsWith("integrations")) {
                    data = fetch(path);
                    data.uri = uri;
                } else {
                    data = fetchAPI(path);
                }
            }
            return data;
        }

        let view;

        if (uri.endsWith("/")) {
            uri = uri.substring(0, uri.length - 1);
        }

        if (uri.startsWith("dialectic")) {
            data.showTopic = true;
            view = await prepareDialectic(await getData(), prepareTemplate("projection"), list);
        } else if (uri.startsWith("topic")) {
            view = await loadTopicView(await getData(), list, true);
        } else if (uri.startsWith("user://")) {
            view = await getUserView(getData());
        } else if (uri.startsWith("integrations")) {
            view = await getUrlView(getData());
        } else if (uri.startsWith("risk")) {
            view = new RiskView(await getData());
        } else if (uri.startsWith("competences")) {
            view = new CompetencesView(await getData(), uri);
            if (uri == "competences://loaded") {
                window.competencesLoaded = view.loaded;
            }
        } else if (uri.startsWith("users://")) {
            view = new UsersView(await getData(), uri);
            if (uri == "users://loaded") {
                window.usersLoaded = view.loaded;
            }
        } else if (uri.startsWith("metrics://")) {
            view = new MetricsView(await getData(), uri);
        } else {
            console.error("unknown feed data", uri);
            return null;
        }

        view.loaded = getData();

        return view;
    }

    async addView(uri, data, list) {

        let view = await this.createView(uri, data, list);

        if (!view) {
            return;
        }


        const container = (view.getContainer && view.getContainer()) ? view.getContainer() : containerNavigator;

        await container.add2Views(view);

        return view;
    }

    cancel() {
        containerNavigator.setMode("next");
        this.pending_action = undefined;
        const checkedInputs = document.querySelectorAll(".select-element input:checked");
        if (checkedInputs && checkedInputs.length > 0) {
            checkedInputs.forEach(checkedInput => {
                checkedInput.checked = false;
            });
        }
    }

    async selectDelegate(delegateInstruction, click) {

        const users = await getDelegates(delegateInstruction);

        containerNavigator.setMode("delegate");

        let user_list = document.querySelector("#delegate .targets");
        user_list.innerHTML = "";
        user_list.style.visibility = "hidden";
        const prepared = prepareTemplate("user");

        if (users.length == 0) {
            blink(document.querySelectorAll("#delegateInvite"));
        } else {
            users.forEach(async user => {
                let user_node = await showUser(prepared.newNode(), user);
                if (user_node) {
                    user_list.style.visibility = "visible";

                    user_node = user_list.appendChild(user_node);

                    user_node.onclick = (evt) => {
                        click(user);
                        containerNavigator.cancel();
                        evt.stopPropagation();
                        evt.preventDefault();
                        return false;
                    };
                }
            });
        }
    }

    align(users) {
        containerNavigator.setMode("align");
        console.log(users, window.lastVoted);
    }

    registerNext(mode, cb) {
        this.pending_action = cb;
        // document.querySelector("#next").textContent = mode;
        containerNavigator.setMode(mode);
    }

    setMode(mode) {
        const menu = document.querySelector("#interaction_menu");
        menu.setAttribute("mode", mode);
        // console.log("set mode", mode);
    }

    susi() {
        // console.log("susi");
        needsLogin();
        // window.location.href = "/auth";
    }

    logout() {
        window.location.href = "/auth/logout";
    }

    callPending() {
        const pending = this.pending_action;
        containerNavigator.cancel();

        if (pending) {
            try {
                pending.call();
            } catch (e) {
                console.error(e);
            }
            delete this.pending_action;
            document.querySelector("#next").textContent = "next";
        }
    }

    back() {
        containerNavigator.cancel();
        this.view_index--;

        if (this.view_index <= 0) {
            return;
        }
        this.views[this.view_index].show();
    }

    async to(index) {
        this.view_index = index;
        // if (index >= this.views.length) {
        //     await loadDialecticsForUser();
        // }

        const view = this.views[index];
        const node = view && view.attachedNode ? view.attachedNode : await this.show(view);
        if (node && node.tagName.toLowerCase() != "none") {
            node.scrollIntoView();
        } else {
            if (!node) {
                throw ("node undefined");
            }
            this.to(index + 1);
        }
    }

    async next() {
        containerNavigator.cancel();
        this.view_index++;
        await this.to(this.view_index);
    }

    encodeHash(obj) {
        // const hash = btoa(JSON.stringify(uri));
        // hash hash = Buffer.from(obj, "base64").toString("utf-8");
        const hash = string2Base64(JSON.stringify(obj));
        return hash;
    }

    decodeHash(hash) {
        if (!hash || hash.length < 3) {
            return {};
        } try {
            hash = hash.substring(1);
            // hash = atob(hash);
            // hash = Buffer.from(obj, "base64").toString("utf-8");
            hash = base642String(hash);

            return JSON.parse(hash);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async onHashChange() {
        try {

            const hash = window.location.hash;

            if (hash.length == 0) {
                return;
            }
            if (hash.startsWith("#f=")) {
                containerNavigator.focusOn = hash.substring(3);
                return;
            }

            // const ct = url.searchParams.get("ct");
            if (hash.startsWith("#ct=")) {
                const ct = hash.substring(4);
                console.log("custom token:", ct);
                window.onCustomToken(ct);
                window.location.hash = "";
                return;
            }

            // sessionStorage.setItem("lastUri", window.location.hash.substring(1));

            const uri = containerNavigator.decodeHash(window.location.hash);
            console.log("onHashChange", uri);

            // dialectic.showTarget(uri.from);
            addSharer(uri.user);
            addSharedUri(uri);
            addReferrer(uri.referrer);

            const event_params = {
                'event_category': "social_referral"
            }

            if (uri.referrer)
                event_params.referrer = uri.referrer;

            gtag('event', "generate_lead", event_params);
            // await loadUserView(uri.user);

            view = await containerNavigator.createView(uri);
            view.byUrlDirect = true;
            await containerNavigator.add2Views(view);
            return view;

            // await Navigator.next();
        } catch (e) {
            console.error(e);
        }
    }

    async competence(competence) {
        showView(new CompetenceView(competence));
    }

    subscribe() {
        const topic = containerNavigator.getCurrentView().uri;
        assert(topic);
        registerUserFCM(() => {
            fetchAPI("/subscribe", {
                method: "POST",
                body: JSON.stringify({
                    topic: topic
                })
            }).then(response => {
                console.log(response);
            });
        });
    }
}


const assert = function (condition, message) {
    if (!condition)
        throw message || "Assertion failed";
}

const Viewer = {

    start() {
        // Navigator.reset();
        document.controller = null;
        Viewer.uiBlock = new Promise(function (resolve) {
            Viewer.resolveUIBlock = resolve;
        });

    },
    blockUi() {
        document.addEventListener("click", async () => {
            const uiBlock = Viewer.uiBlock;
            if (uiBlock) {
                await uiBlock;
                // e.stopPropagation();
                // e.preventDefault();
            }
        }, true);
    },
    end() {
        Viewer.resolveUIBlock();
        Viewer.uiBlock = null;
    },

    oneView: false,

    setView(node, index, container) {
        if (container.firstChild == node) {
            return;
        }

        node = node.tagName.toLowerCase() == "space" ? node : wrap(node);

        if (Viewer.oneView && node.tagName.toLowerCase() == "none") {
            containerNavigator.next();
            return;
        }

        if (Viewer.oneView) {
            container.innerHTML = "";
        }

        if (undefined != index && container.children.length > index) {
            container.insertBefore(node, container.children[index]);
        } else {
            container.appendChild(node);
        }

        // document.mainContent.appendChild(document.createElement("seperator"));
        return node;
    },

    async loadView(view) {
        if (!view.loaded) {
            if (view.loading) {
                await view.loading;
            } else {
                if (view.load) {
                    view.load.bind(view)
                    const response = view.load.call(view);
                    if (response && response.then) {
                        response.then((loaded) => {
                            view.loaded = loaded;
                        });
                        view.loading = response;
                        await view.loading;
                    } else {
                        view.loaded = response;
                    }
                }
            }
        }
        delete view.loading;
        return view.loaded;
    },


    async show(view) {
        let node;
        console.log("showing view", view.viewIndex, view.uri, view.containerNavigator);

        if (view.initView) {
            view.initView();
        }

        if (!view.loaded && view.load) {
            const loaded = Viewer.loadView(view);
            view.loaded = loaded;
        }

        // var lastScrollTop = document.documentElement.scrollTop;
        if (view.show) {
            node = await view.show(view.loaded);
            // console.log("show", view.uri, node, view);
            if (!node) {
                node = document.createElement("none");
            }
        }

        if (!node) {
            console.log("no node", view.uri, node, view);
        }

        if (view.placeholder) {
            view.placeholder.replaceWith(node);
        } else if (view.attachedNode) {
            view.attachedNode.replaceWith(node);
        }

        node.classList.add("content-view");

        // node = Viewer.setView(view, view.viewIndex);
        node.view = view;

        delete view.placeholder;
        view.attachedNode = node;

        node.setAttribute("uri", view.uri);

        // const actual_index = Array.prototype.indexOf.call(document.mainContent.children, node);
        // if (actual_index != view.viewIndex) {
        //     console.error("position not right", node, view.viewIndex, actual_index);
        // }

        const apiLinks = node.querySelectorAll("[apiLink]");
        apiLinks.forEach(el => {
            let url = el.getAttribute("apiLink");
            url = getApiHost() + url;
            el.href = url;
        });

        const promises = [];
        const images = [node, ...node.querySelectorAll("img")].filter(
            el => {
                return el.nodeName.toLowerCase() == ("img");
            });

        const transitions = node.querySelectorAll("[transition]");

        transitions.forEach((transition) => {
            // console.log(img, img.complete);
            const promise = new Promise(async (resolve, reject) => {
                transition.addEventListener("transitionend", () => {
                    resolve("Transition ended");
                });

            });
            promises.push(promise);
        });

        images.forEach((img) => {
            // console.log(img, img.complete);
            if (!img.complete) {
                const promise = new Promise(async (resolve, reject) => {
                    img.addEventListener("load", (event) => {
                        // console.log("onload", img);
                        resolve("Img loaded");
                    });
                    img.addEventListener("error", (event) => {
                        console.log("error loading image");
                        resolve("Img error");
                    });
                });
                promises.push(promise);
            }
        });

        if (view.shown) {
            await view.shown(node);
        }

        view.images = images;
        view.transitions = transitions;

        Promise.all(promises).then(async () => {

            // console.log("showed view", view.viewIndex, view.uri, view.containerNavigator.views);

            const event = new CustomEvent("view-shown", {
                detail: {
                    view: view
                },
            });
            await document.dispatchEvent(event);
        })

        // define an observer instance
        var observer = new IntersectionObserver((entries, opts) => {
            entries.forEach(async entry => {
                if (entry.isIntersecting) {
                    // console.log("view visible", view);
                    if (view.onVisible)
                        view.onVisible.apply(view);
                    try {
                        const event_params = {
                            "item_category": getUriType(view.uri),
                            "item_id": view.uri,
                            "index": view.viewIndex
                        }

                        if (view.title) {
                            event_params.item_name = view.title;
                        }

                        event_params.event_callback = function (...e) {
                            // console.log("view_item", event_params);
                        }

                        gtag("event", "view_item", event_params);
                    } catch (e) {
                        console.error(e);
                    }
                }
            });
        }, {
            threshold: .81 // percentage of target's visible area. Triggers "onIntersection"
        })

        // Use the observer to observe an element
        observer.observe(node);


        return node;

        // document.documentElement.scrollTop = lastScrollTop;

        // if (view.uri)
        //     Navigator.setCurrentURI(view.uri);
    }
}


const Sharer = {

    shareData: {
    },

    shareShortUrl: async function (shareData) {
        return await fetchAPI("/share", {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ user: shareData.user, uri: shareData.uri }),
        }).then(async response => {
            return `${response.host + response.path}/${response.shortUrl}`;
        });
    },

    invite: async function () {

        try {

            const shareData = {
                title: "",
                text: "",
                user: {
                    id: await getMyUser().catch((e) => { }).then((u) => { return u.id }).catch((e) => { return undefined }) || undefined
                }
            }

            if (!shareData.user.id || shareData.user.id == "me") {
                delete shareData.user;
            }

            if (isLoggedIn()) {
                shareData.url = await this.shareShortUrl(shareData);
            } else {
                shareData.url = getApiHost() + "/i?h=" + containerNavigator.encodeHash(this.shareData);
            }

            await navigator.share(shareData);

        } catch (e) {
            console.error(e);
        }

        gtag('event', "share", {
            "content_type": "invite"
        });
    },

    share: async function (node) {

        try {

            const shareData = {
                title: "",
                text: "",
                user: {
                    id: await getMyUser().catch((e) => { }).then((u) => { return u.id }).catch((e) => { return undefined }) || undefined
                }
            }

            if (node.data) {
                shareData.uri = node.data.uri;
            }

            if (!shareData.user.id || shareData.user.id == "me") {
                delete shareData.user;
            }

            if (isLoggedIn()) {
                shareData.url = await this.shareShortUrl(shareData);
            } else {
                shareData.url = getApiHost() + "/s?h=" + containerNavigator.encodeHash(this.shareData);
            }

            await navigator.share(shareData);

            gtag('event', "share", {
                "content_type": shareData.uri
            });

        } catch (e) {
            console.error(e);
        }
    },

    register: async function (shareData) {
        this.shareData = shareData;
    },
}

const getSharer = function () {
    let sharer = [];
    try {
        sharer = getStoredJSONArray("sharer", localStorage);
        const filtered = sharer.filter(user => {
            if (isMe(user)) {
                return false;
            }
            return true;
        });
        if (filtered.length != sharedPromise.length) {
            setStoredJSON("sharer", filteredv, localStorage);
        }

        return filtered;
    } catch (e) { return []; };
    return sharer;
}

const addSharer = function (user) {

    if (!user || !user.id) {
        return;
    }
    assert(user.id && (typeof user.id == "string"))
    if (isMe(user)) {
        return;
    }
    let sharer = getSharer();
    sharer.unshift(user);

    sharer = uniqueArray(sharer, (user) => {
        return user.id;
    });

    while (sharer.length > 10) {
        sharer.pop();
    }
    setStoredJSON("sharer", sharer, localStorage);
}

const getSharedUris = function () {
    const sharedUris = getStoredJSONArray("sharedUris", localStorage);
    return sharedUris;
}

const addSharedUri = function (uri) {
    assert(uri.uri && (typeof uri.uri == "string"))

    let sharedUris = getSharedUris();

    sharedUris.unshift(uri);
    sharedUris = uniqueArray(sharedUris, (uri) => {
        return uri.uri;
    });
    while (sharedUris.length > 10) {
        sharedUris.pop();
    }
    setStoredJSON("sharedUris", sharedUris, localStorage);
}

const addReferrer = function (referrer) {
    if (referrer) {
        try {
            gtag('set', 'page_referrer', referrer);
        } catch (error) {
        }
        console.log("adding referrer: " + referrer);
        if (!localStorage.getItem("firstReferrer")) {
            localStorage.setItem("firstReferrer", referrer);
        }
        localStorage.setItem("lastReferrer", referrer);
        Object.defineProperty(document, "referrer", { get: function () { return referrer; } });
    }
}

const removeSharedUri = function (uri) {
    let sharedUris = getSharedUris();
    for (let i = 0; i < sharedUris.length; i++) {
        const shared_uri = sharedUris[i];
        if (shared_uri.uri == uri) {
            sharedUris.splice(i, 1);
            setStoredJSON("sharedUris", sharedUris, localStorage);
            return;
        }
    }
}


const getDelegates = async function (delegateInstruction = {}, withFollower = true) {
    const delegates = [];

    if (isLoggedIn() && withFollower)
        delegates.push(... await loadFollowing(await getMyUser()).catch((e) => {
            console.log(e);
            return [];
        }) || []);

    delegates.push(...getSharer());
    if (delegates.length < 5) {
        let loaded = [];
        if (delegateInstruction.delegates && delegateInstruction.delegates.length > 0) {
            const resolvedUsers = [];
            const ids = delegateInstruction.delegates.filter(userOrId => {
                if (typeof userOrId == "string") {
                    return true;
                } else {
                    resolvedUsers.push(userOrId);
                }
            });
            if (ids.length > 0) {
                loaded = await fetchAPI("/usersBy?id=" + ids.join(","));
            }
            loaded.push(...resolvedUsers);
        } else if (delegateInstruction.competences && delegateInstruction.competences.length > 0) {
            loaded = await fetchAPI("/usersBy?c=" + delegateInstruction.competences);
        } else {
            const locale = (await getMyUser()).locale;
            loaded = await fetchAPI(`/usersBy?locale=${locale.lang}-${locale.geo}`);
            // console.log("loaded vips:", loaded);
        }
        delegates.push(...loaded || []);
    }
    let ret = unique(delegates, "id");
    ret = ret.slice(0, Math.min(ret.length, 6));
    return ret;
}

const prepareTemplate = (type, list) => {
    const template_id = !type.endsWith("-template") ? type + "-template" : type
    const list_id = type + "-list";

    const template = document.getElementById(template_id);

    // template.style.display = "";

    list = list ? list : document.getElementById(list_id);


    return {
        template: template, list: list, newNode: () => {
            const node = template.cloneNode(true);
            node.id = "";
            // node.style.display = "";
            return node;
        }
    };
}

const follows = async (method, user) => {
    if (!user || !user.id) {
        throw ("user & user.id must be defined");
    }
    fetchAPI(`/follows/${user.id}`, { method: method });
    loadFollower(await getMyUser());
    // loadFollowing();
}

const loadUser = async (id) => {
    // if (!isLoggedIn()) {
    //     return null;
    // }
    const user = await fetchAPI("/user/" + id);
    return user;
}


const showImage = async (node, url) => {
    // console.log("show image", node, url);
    if (!node.onerror) {
        node.onerror = () => {
            node.display = "none";
            console.error(node, url, "image not loaded");
        }
    }

    if (url.endsWith("svg")) {
        node.src = "";
        await fetch(url).then(async response => {
            const imageData = await response.text()

            // node.innerHTML = imageData;
            const n = document.createElement("inlinesvg");
            n.innerHTML = imageData;
            n.classList = node.classList;
            node.replaceWith(n);
            node.style.display = "none";

            node.dispatchEvent(new Event('load'));
            return imageData;
        }).catch(e => {
            console.error(e);
        });

    } else {
        node.src = url;
    }
}

const getAnonymousUser = () => {
    return ({ id: "anonymous", image: "/media/user-silhouette.svg", name: "you" });
}

const getPlaceholderUser = () => {
    return ({ id: "any", image: "/media/question-mark.svg", name: "?" });
}

const getAnonymousUserNode = async () => {
    return await getUserNode(getAnonymousUser());
}

const getUserNode = async (user) => {
    const prepared = prepareTemplate("user");
    return await showUser(prepared.newNode(), user);
}

const showUser = async (node, user, resolve_user_function) => {

    if (resolve_user_function) {
        user = await resolve_user_function(user);
    } else {
        user = await loadUserObject(user);
    }

    if (!user) {
        return;
    }

    const img = node.getElementsByTagName("img")[0];
    img.onerror = () => {
        // console.error(img, "image not loaded");
        // node.style.display = "none";
    }
    // img.src = user.image ? user.image : "/media/user-silhouette.svg";
    await showImage(img, user.image ? user.image : "/media/user-silhouette.svg");

    const name = node.getElementsByTagName("span")[0];
    name.textContent = user.name;

    if (user.type && user.type == "aiuser")
        name.textContent += ".ai";

    node.getElementsByTagName("a")[0].onclick = (e) => { follows("POST", user), e.preventDefault() };
    node.getElementsByTagName("a")[1].onclick = (e) => { follows("DELETE", user), e.preventDefault() };

    node.userid = user.id.indexOf("@") == -1 ? user.id : user.id.split("@")[0];

    if (user.type)
        node.classList.add(user.type);

    if (isMe(user.id)) {
        node.classList.add("me");
    } else {
        node.classList.add("user_" + node.userid);
    }

    // if (user.id != "any") {
    //     img.onclick = async (e) => {
    //         showView(new ProfileView(user));
    //         // Navigator.next();
    //         e.preventDefault();
    //         e.stopPropagation();
    //     }
    // }
    return node;
}

const isMe = (id) => {
    return id == "me" || (!!window.user && id == window.user.id);
}

const loadFollows = async (uri) => {
    const follower = await fetchAPI(uri) || [];
    const followers = [];
    follower.forEach(f => {
        followers.push((f.follows));
    });
    return followers;
}

const loadFollower = async (user) => {
    return loadFollows(`/follows/follower/${user.id}`);
}

const loadFollowing = async (user) => {
    return loadFollows(`/follows/following/${user.id}`);
};


const loadDialecticsForUser = async (url, attach2, max) => {

    if (!url) {
        url = "/dialectics";
    }

    let dialecticsCount = 0;

    const template = prepareTemplate("projection");

    const dialectics = await fetchAPI(url) || [];

    for (const dialectic of dialectics) {
        if (max && dialecticsCount >= max) {
            // return dialectics.splice(0, max);
            return dialectics;
        } else {
            dialectic.showtopic = true;
        }

        await prepareDialectic(dialectic, template, (attach2 ? attach2 : containerNavigator.container));
        dialecticsCount++;
    }

    return dialectics;
};

const loadDialecticView = async (id, withTopic) => {
    const dialectic = await fetchAPI("/dialectic/" + id);
    dialectic.showtopic = withTopic;
    if (!dialectic.projection_targets)
        dialectic.projection_targets = [];

    await prepareDialectic(dialectic);
    await containerNavigator.add2Views(dialectic);

    return dialectic;
}

const loadDialecticViews = async (id, p_template, list) => {

    const dialectics = await fetchAPI("/dialectics/" + id.split("/")[0]);

    for (let i = 0; i < dialectics.length; i++) {
        const dialectic = dialectics[i];
        await prepareDialectic(dialectic, p_template, list);
        await containerNavigator.add2Views(dialectic);
    }

    return dialectics;
}

const loadTopicView = async (uri, attach2, withLinks) => {

    let topic = typeof uri == "string" ? undefined : uri;
    uri = typeof uri == "string" ? uri : topic.uri;

    const topicView = {
        uri: toUri("topic", uri),
        async load(params) {

            assert(this.containerNavigator != null);

            topic = topic ? topic : await fetchAPI("/topic/" + getUriId(uri));
            topic.id = getUriId(topic.uri);

            // if (!topic.previewDialectics) {
            //     topic.previewDialectics = await loadDialecticsForUser("/dialecticsForTopic/" + topic.id, attach2, 100);
            // } else {
            //     for (let i = 0; i < topic.previewDialectics.length; i++) {
            //         const dialectic = topic.previewDialectics[i];
            //         await prepareDialectic(dialectic);
            //     }
            // }

            // for (let i = 0; i < topic.previewDialectics.length; i++) {
            //     const dialectic = topic.previewDialectics[i];
            //     await this.containerNavigator.add2Views(dialectic);
            // }

            return topic;
        },
        show: async (topic) => {
            topic = await topic;
            let withLinks = true;
            let previewDialectics = 100;

            const node = await (document.templates.querySelector("#topic-template").cloneNode(true));
            node.id = "";
            const linkContainer = node.querySelector(".links");
            // node.querySelector(".title").textContent = topic.title;

            // console.log(topic);

            if (topic.tag && topic.tag != "null") {
                node.querySelector(".tag").textContent = "#" + topic.tag;
            }
            node.querySelector(".text").textContent = topic.descr;

            const setImg = (url) => {
                const img = node.querySelector(".image img");
                // document.createElement("img");
                img.setAttribute("referrerpolicy", "no-referrer");
                // img.style.backgroundImage = "url(" + url + ")";
                img.src = url;
                topic.image = url;
                img.onerror = function () {
                    img.style.display = "none";
                }
                return img;
            }

            showActions(node.querySelector(".actions"), topic.uri);

            await showCompetences(node.querySelector(".competences"), topic.competences);

            topic.urls.forEach((url, index) => {

                const a = document.createElement("a");
                if (url.image && !topic.image) {
                    const img = setImg(url.image);
                    linkContainer.appendChild(a);
                }

                if (withLinks) {
                    // if (index >= 1) {
                    //     return;
                    // }
                    const span = document.createElement("span");
                    span.textContent = decodeURIComponent(url.title) || new URL(url.url).host;
                    a.appendChild(span);
                    a.href = url.url;
                    linkContainer.appendChild(a);
                }
            });

            if (topic.image) {
                setImg(topic.image);
            } else {
                node.querySelector(".image").style.display = "none";
            }

            node.addEventListener("mousedown", (e) => {
                console.log(e);
                gtag('event', "select_content", {
                    content_type: "topic",
                    content_id: topic.id
                });
                return false;
            });

            // if (topic.previewDialectics) {
            //     const dialectics = topic.previewDialectics; //await loadDialecticsForUser("/dialecticsForTopic/" + topic.uri, attach2, previewDialectics);
            //     if (dialectics.length == 0 && topic.risks.length == 0) {
            //         error("empty topic", topic.uri);
            //         return node;
            //     }
            //     node.onclick = async function (e) {
            //         await showView(new DialecticsView(dialectics, topic));
            //         e.preventDefault();
            //         e.stopPropagation();
            //         return false;
            //     }
            // }

            return node;
        },

        onVisible: function () {
            this.containerNavigator.registerCurrentView(this.uri, this.loaded.title);
        }
    }
    // await Navigator.add2Views(topicView);
    return topicView;
}

const loadTopicViews = async (uri, attach2, withLinks) => {
    if (!uri.startsWith("/"))
        uri = "/" + uri;
    const topics = await fetchAPI(uri);
    for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        topic.id = getUriId(topic.uri);

        await containerNavigator.add2Views(
            await loadTopicView(topic.uri, attach2, withLinks, topic)
        )
    }
};

const interactionPrevent = (reason, interaction) => {
    gtag('event', "prevent", {
        interaction: interaction,
        reaseon: reason
    });
}

const appendBar = (value, attach2, fct) => {
    const bar = document.createElement("div");
    bar.classList.add("bar");
    bar.value = value;
    bar.style.width = bar.value + "%";

    if (attach2)
        attach2.appendChild(bar);

    if (!fct) {
        bar.style.opacity = bar.value / 100;
    } else {
        fct(bar, value);
    }

    return bar;
}

function percentageToHsl(percentage, hue0, hue1) {
    var hue = (percentage * (hue1 - hue0)) + hue0;
    return 'hsl(' + hue + ', 100%, 50%)';
}



function hexToRgb(hex) {
    // Remove the # if present
    hex = hex.replace("#", "");

    // Parse the hex value
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Handle invalid hex values
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        return null;
    }

    return [r, g, b];
}

function blendColor(color, factor, blendTowardsWhite = false, greyThreshold = 0.081) {
    // Validate factor input
    if (factor < 0 || factor > 1) {
        throw new Error("Factor must be between 0 and 1.");
    }

    // Remove the # if present
    color = color.replace("#", "");

    // Parse the hex value
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        throw new Error("Invalid hex code format.");
    }

    const isGrey = Math.abs(r - g) < 255 * greyThreshold && Math.abs(r - b) < 255 * greyThreshold && Math.abs(g - b) < 255 * greyThreshold;


    let blendedR, blendedG, blendedB;

    if (isGrey) {
        // For grey colors, transition more quickly.  Adjust the power for steeper transition
        const greyFactor = Math.pow(factor, 3.9); // Adjust exponent for transition speed. Higher exponent = faster

        if (blendTowardsWhite) {
            blendedR = Math.round(r + (255 - r) * greyFactor);
            blendedG = Math.round(g + (255 - g) * greyFactor);
            blendedB = Math.round(b + (255 - b) * greyFactor);
        } else {
            blendedR = Math.round(r * (1 - greyFactor));
            blendedG = Math.round(g * (1 - greyFactor));
            blendedB = Math.round(b * (1 - greyFactor));
        }
    } else {
        if (blendTowardsWhite) {
            blendedR = Math.round(r + (255 - r) * factor);
            blendedG = Math.round(g + (255 - g) * factor);
            blendedB = Math.round(b + (255 - b) * factor);
        } else {
            blendedR = Math.round(r * (1 - factor));
            blendedG = Math.round(g * (1 - factor));
            blendedB = Math.round(b * (1 - factor));
        }
    }

    // Convert back to hex
    const blendedHex = "#" + [blendedR, blendedG, blendedB]
        .map(x => {
            const hex = x.toString(16);
            return hex.length === 1 ? "0" + hex : hex; // Pad with 0 if needed
        })
        .join("");

    return blendedHex;
}


function extrapolate(factor, midpoint, range, speed = "faster") {
    const lowerBound = midpoint - range / 2;
    const upperBound = midpoint + range / 2;

    if (factor >= lowerBound && factor <= upperBound) {
        let multiplier;

        if (speed == "faster") {
            multiplier = 0.25; // Steeper slope (faster transition)
        } else if (speed == "slower") {
            multiplier = 0.75; // Shallower slope (slower transition)
        }

        if (factor < midpoint) {
            factor = lowerBound + (factor - lowerBound) * multiplier;
        } else {
            factor = upperBound - (upperBound - factor) * multiplier;
        }

    }

    if (factor < 0) {
        factor = 0;
    } else if (factor > 1) {
        factor = 1;
    }

    return factor;
}

function contrastColor(hex, threshold = 0.05) {
    hex = hex.replace("#", "");

    let r = 0, g = 0, b = 0; // Initialize to avoid potential undefined issues

    if (hex.length === 6) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    } else if (hex.length === 8) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    } else {
        console.error("Invalid hex code length:", hex); // Log the error
        return "black"; // Or throw an error if you prefer
    }

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        console.error("Invalid hex code format:", hex); // Log the error
        return "black";
    }

    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // luminance = extrapolate(luminance, 0.50, 0.12);

    console.log(luminance); // Check the RGB and luminance values
    // return blendColor("#FFFFFF", luminance);
    return luminance > threshold ? "black" : "white";
}

const prepareStatementNode = async (dialectic, dialectic_node, statement_template, statement_node, statement, voted) => {
    // dialectic_node.insertBefore(document.createElement("separator"), statement_template);
    statement_node = dialectic_node.insertBefore(statement_node, statement_template);

    // statement_node.getElementsByTagName("span")[0].textContent = statement.percentVotes ? statement.percentVotes : "";
    if (statement.percentVotes) {
        appendBar(statement.percentVotes, statement_node);
    }

    statement_node.querySelector(".tag").textContent = "!" + statement.tag;
    statement_node.querySelector(".text").textContent = statement.descr;

    let votedForThis = isLoggedIn() && dialectic.userVoted && dialectic.userVoted.value && dialectic.userVoted.value == statement.value;

    const voters_node = statement_node.querySelector(".voters");
    const prepared = prepareTemplate("user", voters_node);

    if (statement.votes && statement.votes.length > 0) {
        statement_node.classList.add("hasVotes");
        for (let i = 0; i < statement.votes.length; i++) {

            const voter = statement.votes[i];

            if (voter && voter != "anonymous") {
                const userNode = await showUser(prepared.newNode(), voter);
                if (userNode) {
                    const doubleAdd = dialectic_node.querySelector(".user_" + userNode.userid);
                    if (doubleAdd)
                        doubleAdd.parentNode.removeChild(doubleAdd);
                    voters_node.appendChild(userNode);
                }
            }
        };
    }

    if (votedForThis) {
        statement_node.classList.add("voted");
        if (isLoggedIn()) {
            const userNode = await showUser(prepared.newNode(), "me");
            const doubleAdd = voters_node.querySelector(".user_" + userNode.userid);
            if (doubleAdd)
                voters_node.removeChild(doubleAdd);
            voters_node.appendChild(userNode);
        }
    }

    prepareRadioNode(dialectic, statement_node, votedForThis);

    const onclick = statement_node.onclick;
    statement_node.onclick = async () => {

        const checked = onclick();
        if (checked) {
            if (votedForThis) {
                containerNavigator.registerNext("voted");
                return;
            }

            // Navigator.registerNext("vote", async function () {
            if (isLoggedIn()) {
                if (dialectic.vote(statement)) {
                    if (dialectic.onVoted)
                        dialectic.onVoted();
                }
            }
            // Navigator.cancel();
        }
    }
}

const prepareRadioNode = (dialectic, statement_node, checked) => {

    const radioButton = statement_node.getElementsByTagName("input")[0];
    radioButton.name = dialectic.id;
    radioButton.setAttribute("name", dialectic.id);

    radioButton.classList.add("radiobutton");
    radioButton.checked = checked;
    if (radioButton.checked) {
        // console.log(radioButton.checked, radioButton);
    }
    statement_node.onclick = function () {
        const checked = radioButton.checked;
        if (checked) {
            radioButton.checked = false;
            containerNavigator.cancel();

            gtag('event', "select_item", {
                item_list_id: "feed",
                items: [
                    {
                        item_id: dialectic.id,
                        item_category: "dialectic"
                    }
                ]
            });

        } else {
            radioButton.checked = true;
        }
        return radioButton.checked;
    }

    radioButton.onclick = (e) => {
        statement_node.onclick(e);
    }
    // blink(radioButton);

    return radioButton
}

const prepareDifferentiatorNode = (dialectic_node, dialectic, p, list) => {
    let differentiate_node = dialectic_node.querySelector(".differentiator");

    prepareRadioNode(dialectic, differentiate_node, false);

    addAction("createNew", differentiate_node);

    const onclick = differentiate_node.onclick;
    differentiate_node.onclick = function () {
        const checked = onclick();
        if (checked) {
            if (!isLoggedIn()) {
                needsLogin();
                return;
            }

            // document.mainContent.classList.add("focusViewContainer");
            dialectic.attachedNode.classList.add("focusView");
            console.log(dialectic.attachedNode);

            containerNavigator.setMode("differentiate");
            const textarea = document.querySelector("#differentiate textarea");
            textarea.focus();
            textarea.scrollIntoView();
            containerNavigator.registerNext("differentiate", async function () {
                // const value = differentiate_node.querySelector("textarea").value;
                const value = textarea.value;
                dialectic.differentiate(value);
            });
        } else {
            // document.mainContent.classList.remove("focusViewContainer");
            dialectic.attachedNode.classList.remove("focusView");
            dialectic.attachedNode.scrollIntoView();
        }
    }
    return differentiate_node;
}



const showDelegates = async function (inNode, delegates, click) {

    inNode.innerHTML = "";

    let more;

    if (delegates.length) {
        more = inNode.appendChild(clone("questionmarkicon"));
        more.classList.add("avatar");
        more.classList.add("button");
        more.classList.add("user");
        // await showImage(more, "/media/add_24dp_FILL0_wght400_GRAD0_opsz24.svg");
    }

    const showTarget = async (target) => {
        const prepared = prepareTemplate("user", inNode);
        if (target) {
            const userNode = await showUser(prepared.newNode(), target, target.indexOf("/") > 0 ? () => {
                return {
                    id: target,
                    name: target.split("/")[1]
                }
            } : undefined);
            if (!userNode || !userNode.tagName) {
                return;
            }
            if (!more) {
                inNode.appendChild(userNode);
            } else {
                inNode.insertBefore(userNode, more);
            }

            delete userNode.onclick;

            if (click) {
                userNode.onclick = (evt) => {
                    try {
                        click();
                    } catch (e) {
                        console.error(e);
                    } finally {
                        evt.preventDefault();
                        evt.stopPropagation();
                        return false;
                    }
                };
            }
            if (!delegates.length) {
                userNode.classList.add("selected");
            }
        }
    }

    if (undefined == delegates.length) {
        delegates = [delegates]
    }

    await asyncForEach(delegates, async target => {
        await showTarget(target);
    });

    // await inNode.mutex.runExclusive(run);
    return showTarget;
}

const clone = function (id) {
    const cloneme = document.templates.querySelector(`[cloneme='${id}']`);
    const cloned = cloneme.cloneNode(true);
    return cloned;
}

const prepareDelegatorNode = async (targetUser, dialectic_node, dialectic, p, list) => {

    const projection_targets = dialectic_node.querySelector(".projection-targets");

    const click = function (e) {
        const checked = onclick();
        if (checked) {
            containerNavigator.selectDelegate(dialectic, function (user) {

                if (user != dialectic.userDelegated) {
                    // Navigator.setMode("delegating");
                    // Navigator.registerNext("delegating", async function () {
                    dialectic.delegate(user);
                    dialectic.showTarget(user);
                    dialectic.userDelegatedNew = target;
                }

                return false;

            });
        }
    };

    dialectic.showTarget = await showDelegates(projection_targets, targetUser ? [targetUser] : dialectic.delegates, click);
    dialectic.userDelegatedNew = targetUser;

    const delegator_node = dialectic_node.querySelector(".delegator");

    addAction("delegating", delegator_node, null, click);

    const delegated = !!dialectic.userDelegated;
    prepareRadioNode(dialectic, delegator_node, delegated);
    const onclick = delegator_node.onclick;
    delegator_node.onclick = click;
    return delegator_node;
}

const prepareDialectic = async (dialectic, cloned_template, list) => {

    if (!list) {
        list = dialectic.container ? dialectic.container : document.mainContent;
    }

    // if (!cloned_template) {
    cloned_template = prepareTemplate("projection", list).newNode();;
    // }
    dialectic.container = list;
    dialectic.root_node = cloned_template;

    // dialectic.id = getUriId(dialectic.uri);
    // dialectic.uri = toUri("dialectic", dialectic.id);
    dialectic.load = function () {
        return dialectic;
    }

    dialectic.onVisible = function () {
        const title = this.extra && this.extra.context ? this.extra.context : undefined;
        containerNavigator.registerCurrentView(this.uri, title);
    }

    dialectic.show = async function () {

        // list.innerHTML = "";
        if (!!dialectic.attachedNode) {
            dialectic.attachedNode.replaceWith(wrap(dialectic.root_node));
            dialectic.attachedNode = dialectic.root_node;
        } else {

            if (dialectic.topic && dialectic.showtopic) {
                await containerNavigator.addView("topic://" + dialectic.topic);
            }

            // dialectic.attachedNode = list.appendChild(dialectic.root_node);
        }

        const p = dialectic.root_node; //dialectic.attachedNode;
        const topicinfo = p.querySelector(".related_topic");
        const competences = p.querySelector(".competences");
        let dialectic_node = p.querySelector(".dialectic");

        if (dialectic.extra && dialectic.extra.context)
            p.querySelector(".dialecticContext").textContent = dialectic.extra.context;

        showActions(p.querySelector(".actions"), toUri("dialectic", dialectic.uri));

        // dialectic_template.parentNode.removeChild(dialectic_template);
        // const dialectic_node = p.appendChild(dialectic_template.cloneNode(true));

        let statement_template = dialectic_node.querySelector(".statement[template]");
        statement_template.style.display = "none";
        // statement_template.removeAttribute("template");
        const oldStatements = dialectic_node.querySelectorAll(".statement");
        oldStatements.forEach(st => {
            if (st.statement) {
                st.parentNode.removeChild(st);
            }
        });

        let voted = !!dialectic.userVoted;

        let totalVotes = 0;
        dialectic.statements.forEach(statement => {
            totalVotes += statement.voted ? statement.voted : 0;
        });

        for (let i = 0; i < dialectic.statements.length; i++) {
            const statement = dialectic.statements[i];

            let s = statement_template.cloneNode(true);
            s.style.display = "";
            s.removeAttribute("template");
            s.statement = statement;

            statement.percentVotes = Math.round(100 * (statement.voted ? statement.voted : 0) / totalVotes);
            // if (!voted)
            //     voted = statement.votes && statement.votes.includes("me");
            await prepareStatementNode(dialectic, dialectic_node, statement_template, s, statement, voted);
        };

        if (voted) {
            dialectic_node.classList.add("voted");
        }
        // statement_template.parentNode.removeChild(statement_template);

        const differentiatorNode =
            await prepareDifferentiatorNode(dialectic_node, dialectic, p, list);
        // dialectic_node.insertBefore(document.createElement("separator"), differentiatorNode);

        const delegatorNode =
            await prepareDelegatorNode(dialectic.userDelegated ? dialectic.userDelegated : dialectic.userDelegatedNew, dialectic_node, dialectic, p, list);
        // dialectic_node.insertBefore(document.createElement("separator"), delegatorNode);

        await showCompetences(competences, dialectic.competences);

        if (voted && !hasSubscriptionPermission()) {
            // Navigator.add2Views(toActionView("vote_sub", p.querySelector(".related_actions")));
        }

        // addAction("share", wrap(p.querySelector(".related_actions_post")));

        return p;
    }



    const dialecticInteract = async (uri, body) => {
        const userActionResponse = await fetchAPI(uri, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })

        let newDialectic = userActionResponse.userAction.dialectic;
        Object.assign(dialectic, newDialectic);
        newDialectic = dialectic;
        // dialectic.container.innerHTML = "";

        prepareDialectic(newDialectic, dialectic.root_node, undefined);
        // Navigator.views[Navigator.view_index] = (newDialectic);
        newDialectic.show();
        handleUserActionResponse(userActionResponse);
        // Navigator.cancel();
        return userActionResponse;
    }

    dialectic.vote = async (statement) => {
        if (!isLoggedIn()) {
            interactionPrevent("auth", "vote");
            needsLogin();
            return false;
        }
        containerNavigator.registerNext("voting");
        const userActionResponse = await dialecticInteract(`/projection/${dialectic.id}`, { value: statement.value });
        containerNavigator.registerNext("voted");
        dialectic.root_node.classList.add("voted");
        gtag('event', "vote", {
            dialectic: dialectic.id,
            statement: statement.value
        });

        const event = new CustomEvent("voted", {
            detail: {
                dialectic: dialectic,
                statement: statement,
                userActionResponse
            },
        });
        document.dispatchEvent(event);

        return true;
    }

    dialectic.delegate = (user) => {
        if (!isLoggedIn()) {
            interactionPrevent("auth", "delegate");
            needsLogin();
            return false;
        }
        dialecticInteract(`/projection/${dialectic.id}`, { target: user.id });
        gtag('event', "delegate", {
            dialectic: dialectic.id,
            target: user.id
        });
        return true;
    }

    dialectic.differentiate = (value) => {
        if (!isLoggedIn()) {
            interactionPrevent("auth", "differentiate");
            needsLogin();
            return false;
        }
        dialecticInteract(`/dialectic/${dialectic.id}`, { input: value });
        gtag('event', "differentiate", {
            dialectic: dialectic.id,
            input: value
        });
    }
    return dialectic;
}

const isLoggedIn = function () {
    return window.user && window.user.id && window.user.id != "me" && window.user.image;
}

const needsLogin = function () {
    document.body.setAttribute("loggedin", "false");
    // Navigator.registerNext("susi");
    const el = document.querySelectorAll("#susi");
    blink(el);
    // throw ("User needs Login");
}

const onLoggedIn = function () {
    if (isLoggedIn()) {
        document.body.setAttribute("loggedin", "true");
        // Navigator.registerNext("susi");
        const el = document.querySelectorAll("#susi");
        // blink(el);
        fadeOut(el);

        if (window.user.image) {
            // myavatar.src = window.user.image;
            const myavatar = document.querySelector("#myavatar");
            myavatar.style.content = `url(${window.user.image})`;
            myavatar.style.filter = "none";
        }
        // throw ("User needs Login");
    }
}

window.onLoggedIn = onLoggedIn;
window.needsLogin = needsLogin;

let onceAnimationEnd = (el, animation) => {
    return new Promise(resolve => {

        const onAnimationEndCb = () => {
            el.removeEventListener('animationend', onAnimationEndCb);
            el.classList.remove(animation);
            resolve();
        }

        if (!el.classList.contains(animation)) {
            el.style.animation = 'none';
            // el.offsetHeight; /* trigger reflow */
            el.style.animation = null;

            el.addEventListener('animationend', onAnimationEndCb)
            //   el.style.animation = animation;
            el.classList.add(animation);
        }
    });
}

const animate = function (els, animation) {
    if (!els.length && els.length != 0) {
        els = [els];
    }

    const promises = [];
    els.forEach(el => {
        promises.push(onceAnimationEnd(el, animation));
    });
    return Promise.all(promises);
}

const endAnimation = function (els, animation) {
    if (!els.length) {
        els = [els];
    }
    els.forEach(el => {
        el.classList.remove(animation);
        el.style.animation = 'none';
        el.offsetHeight; /* trigger reflow */
        el.style.animation = null;
    });
}

const blink = function (els) {
    return animate(els, "anim_blink");
}

const fadeOut = function (els) {
    return animate(els, "anim_fadeOut");
}

const loadCounter = {
    count: 0
}

const load = function (stop) {
    // console.log("load", stop);
    if (stop) {
        loadCounter.count--;
        if (loadCounter.count <= 0) {
            loadCounter.count = 0;
            endAnimation([document.loader], "anim_load");
        }
        return;
    }
    loadCounter.count++;
    // document.avatar.src = "media/logo.svg"
    return animate([document.loader], "anim_load");
}

const getCompetences = async function (user) {
    return user.competences ? user.competences : isMe(user.id) ? await getMyCompetences() : await fetchAPI("/competencemap4/" + user.id);
}

const getMyCompetences = async function () {
    const usercompetences = await getMyUser().competences;
    const compt = window.getPreviewObject().competences || [["politics", 3], ["technology", 2], ["science", 1]]
    return usercompetences && usercompetences.length > 3 ? usercompetences : uniqueArray([usercompetences || [], ...
        compt
    ], (a) => {
        return a[0]
    });
}



Object.defineProperty(window, 'user', {
    set: function (newValue) {
        const oldValue = this._user;
        // Your custom logic here when propertyX is set
        console.log('user set to:', newValue, oldValue);

        // You can also store the value internally if needed
        this._user = newValue; // Using a hidden property

        if (isLoggedIn()) {
            onLoggedIn()
        } else {
            needsLogin();
        }
    },
    get: function () {
        // If you implemented a setter, you may want to implement a getter.
        // This example returns the stored value or undefined if not set.
        return this._user;
    },
    enumerable: true, // Optional: makes the property appear in for...in loops
    configurable: true // Optional: allows the property to be deleted or reconfigured
});


const getMyUser = async function () {

    if (!window.user) {
        if (!window.preview) {
            window.user = getStoredJSON("user");
            if (!window.user) {
                window.user = await loadUser("me").catch(err => {
                    console.log(err);
                });
            }
        }
        if (!window.user) {
            // if (window.fb_user && !window.preview) {
            //     window.signInUser();
            // }
            window.user = { id: "me", name: "You after login...", image: "/media/user-silhouette.svg" }
        } else {
            setStoredJSON("user", window.user);
            window.milestones = user.milestones;
        }

        if (!window.user.locale) {
            if (window.user.locale && window.user.locale.lang && window.user.locale.geo) {
                window.user.locale = window.user.locale.lang + "-" + window.user.locale.geo;
            } else {
                window.user.locale = "en-US";
            }
        }


        try {
            setUserProperties({
                locale: user.locale,
                user_id: window.user.id,
                debug_mode: (debug ? "true" : "false"),
            });
        } catch (error) {
            console.log(error);
        }


    }

    return await window.user;
}

function logConsent() {

    // Retrieve all the fields
    const cookie = CookieConsent.getCookie();
    const preferences = CookieConsent.getUserPreferences();

    // In this example we're saving only 4 fields
    const userConsent = {
        consentId: cookie.consentId,
        acceptType: preferences.acceptType,
        acceptedCategories: preferences.acceptedCategories,
        rejectedCategories: preferences.rejectedCategories
    };

    // Send the data to your backend
    // replace "/your-endpoint-url" with your API
    fetchAPI('/cconsent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userConsent)
    });
}

try {
    CookieConsent.run({

        onFirstConsent: () => {
            logConsent();
        },

        onChange: () => {
            logConsent();
        }
    });
} catch (error) {
}

const showActions = (node, uri) => {
    node.innerHTML = "";
    addAction("share", node, { uri: uri });
}

const showCompetences = async (node, competences) => {

    node.innerHTML = "";

    if (!competences) {
        return;
    }
    // console.log(node, competences);
    await asyncForEach(competences, (competence => {
        const competence_node = node.appendChild(document.createElement("a"));

        competence_node.classList.add("tag");
        competence_node.classList.add("competence");

        competence_node.textContent = competence;
        competence_node.onclick = function (e) {
            containerNavigator.competence(competence);
            e.preventDefault();
            e.stopPropagation();
        }
    }));
}

/**
 * Waits for a specific element to be added to the DOM using MutationObserver.
 *
 * @param {HTMLElement} targetNode The parent node to observe.
 * @param {string} selector The CSS selector of the element to wait for.
 * @param {function} callback The function to execute when the element is found.
 * @param {object} [options] Optional options for MutationObserver.
 * @param {boolean} [options.subtree=true] Whether to observe all descendants of the targetNode.
 * @param {boolean} [options.childList=true] Whether to observe changes to the children of the targetNode.
 */
function waitForElement(targetNode, callback, selector, options = {}) {

    let initialElement = targetNode.querySelector(selector);
    if (initialElement) {
        callback(initialElement);
        return;
    }
    const { subtree = true, childList = true } = options;

    const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
            if (mutation.type === 'childList') {
                const element = targetNode.querySelector(selector);
                if (element) {
                    callback(element);
                    observer.disconnect();
                    return;
                }
            }
        }
    });

    observer.observe(targetNode, { childList, subtree });
    try {
        if (selector) {
            // Handle the case where the element is already present
            initialElement = targetNode.querySelector(selector);
            if (initialElement) {
                callback(initialElement);
            }
        } else {
            callback(targetNode);
        }
    } catch (e) {
        console.error(e);
    } finally {
        observer.disconnect();

    }

}

window.waitForElement = waitForElement;

function base642String(base64) {
    const binString = atob(base64);
    return binString;
}

// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
function string2Base64(binString) {
    // const binString = String.fromCodePoint(...bytes);
    let hash = btoa(binString);
    return hash;
}

const getUri = (uri) => {
    if (typeof uri != "string") {
        if (uri.uri) {
            uri = uri.uri;
        }
    }
    return uri;
}

const toUri = (type, uri) => {
    uri = getUri(uri);
    if (uri.indexOf("://") > 0) {
        return uri;
    }
    return type + "://" + uri;
}

const getUriId = (uri) => {
    uri = getUri(uri);
    if (uri.indexOf("://") <= 0) {
        return uri;
    }
    return uri.split("://")[1];
}

const getUriType = (uri) => {
    uri = getUri(uri);
    if (uri.indexOf("://") <= 0) {
        return uri.split("/")[1];
    }
    return uri.split("://")[0];
}

const isUri = (type, uri) => {
    uri = getUri(uri);
    return uri.startsWith(type + "://");
}

const uriToPath = (uri) => {
    const path = "/" + getUriType(uri) + "/" + getUriId(uri);
    return path;
}

const initTabs = (tabcontainer, active) => {
    let tabs = tabcontainer.querySelectorAll(".tab");

    // tabs.parentNode.removeChild(tabs);
    interaction_menu.querySelector("#tabs").innerHTML = "";
    tabs.forEach(tab => {
        if (tab.nodeType != 1) {
            return;
        }
        interaction_menu.querySelector("#tabs").appendChild(tab);
    });

    tabs = interaction_menu.querySelector("#tabs").querySelectorAll(".tab");

    tabs.forEach(tab => {
        if (tab.nodeType != 1) {
            return;
        }

        if (tab.getAttribute("name") == active) {
            tab.setAttribute("active", "true");
        }

        const selector = "tabview[name=" + tab.getAttribute("name") + "]";
        const tabview = tab.parentNode.parentNode.querySelector(selector);
        tab.tabview = tabview;

        const activate = function (tab) {
            if (!tab.onActiveCallBack) {
                return;
            }
            const view = tab.onActiveCallBack();
            // tab.tabview.style.display = "block";
            // tabview.appendChild(view);
        }

        tab.onclick = function () {
            tabs.forEach(tab => {
                tab.setAttribute("active", "false");
                // tab.tabview.style.display = "none";
            });
            tab.setAttribute("active", "true");
            activate(tab);
        }

        // if (tab.getAttribute("active") == "true") {
        //     activate(tab);
        // }

        tab.onActive = function (cb) {
            tab.onActiveCallBack = cb;
            if (tab.getAttribute("active") == "true") {
                activate(tab);
            }
        }
        // console.log(tab);
    });

    return interaction_menu.querySelector("#tabs");
}

const readUserStream = async function () {
    if (!window.userStream)
        window.userStream = fetchAPI("/projections").then(projections => {

            const votes = [];
            const delegations = [];
            if (projections) {
                projections.sort((a, b) => {
                    if (a.created > b.created) {
                        return -1;
                    }
                });
                projections.forEach(projection => {
                    if (projection.target) {
                        delegations.push(projection);
                    } else if (projection.value) {
                        votes.push(projection);
                    }
                });
            }

            return { votes, delegations };
        });
    return window.userStream;
}

const hasFeedNews = async function () {

    const lastDelegationTime = localStorage.getTime("lastDelegationTime") | 0;
    readUserStream().then(stream => {

        stream.delegations.forEach(delegation => {
            if (new Date(delegation.created).getTime() > lastDelegationTime) {
                return true;
            }
        });

        return false;
    });
}


const wrap = function (node) {

    if (true) {
        return node;
    }

    const outer = document.createElement("div");
    const inner = document.createElement("div");
    inner.classList.add("inner_card");

    inner.appendChild(node);

    outer.appendChild(document.createElement("separator"));
    outer.appendChild(inner);
    // outer.appendChild(document.createElement("separator"));
    outer.appendChild(document.createElement("space"));

    return outer;
}

const wrapOld = function (attach2, node) {

    const outer = document.createElement("div");
    const inner = document.createElement("div");
    inner.classList.add("inner_card");

    if (node)
        inner.appendChild(node);

    outer.appendChild(document.createElement("separator"));
    outer.appendChild(inner);
    // outer.appendChild(document.createElement("separator"));
    outer.appendChild(document.createElement("space"));

    const overriddenAppendChild = inner.appendChild;
    inner.appendChild = function (node) {
        node = overriddenAppendChild.call(inner, node);
        if (attach2)
            attach2.appendChild(outer);
        return node;
    }

    const overriddenRemoveChild = inner.removeChild;
    inner.removeChild = function (node) {
        outer.style.display = "none";
        node = overriddenRemoveChild.call(inner, node);
        if (attach2) {
            attach2.removeChild(outer);
        } else {
            containerNavigator.next();
        }
        return node;
    }

    const overriddenReplaceChild = inner.replaceChild;
    inner.replaceChild = function (node, node2) {
        node = overriddenReplaceChild.call(inner, node, node2);
        // if (attach2) {
        //     attach2.replaceChild(outer);
        // } 
        return node2;
    }

    return inner;
}

const useSplitContent = () => {
    return !window.preview && document.body.clientWidth > 720;
}

const initMyTabs = function (active) {
    let my_view = document.templates.querySelector("#myview-template").cloneNode(true);
    containerNavigator.setMode("profile");
    //  my_view = list.appendChild(my_view);

    const tabs = initTabs(my_view.querySelector("tabs"), active);

    InboxView.tabname = "hashtag";
    HomeView.tabname = "mention";

    InboxView.tab = tabs.querySelector("*[name=" + InboxView.tabname + "]");
    HomeView.tab = tabs.querySelector("*[name=" + HomeView.tabname + "]");
    FeedView.tab = interaction_menu.querySelector('#appicon');

    InboxView.loadTab = (async function () {
        showView(new InboxView(), useSplitContent() ? window.leftNavigator : undefined);
    });

    HomeView.loadTab = (async function () {
        showView(new HomeView(), useSplitContent() ? window.rightNavigator : undefined);
    });

    FeedView.loadTab = FeedView.tab.onclick = async function () {
        // showView(new HomeView());
        showView(new FeedView());
        // showView(new ProfileView(await getMyUser()));
    };

    if (useSplitContent()) {
        tabs.style.display = "none";
    } else {
        tabs.style.display = "";
        InboxView.tab.onActiveCallBack = InboxView.loadTab;
        HomeView.tab.onActiveCallBack = HomeView.loadTab;
    }

    interaction_menu.querySelector('#home').onclick = FeedView.loadTab;
}

const loadTabContent = (active) => {
    if (useSplitContent()) {
        InboxView.loadTab();
        FeedView.loadTab();
        HomeView.loadTab();
    }
    else {
        FeedView.loadTab();
    }
}

const showView = async function (view, _containerNavigator = containerNavigator) {
    window.scrollTo(0, 0);
    _containerNavigator.reset();

    animate(_containerNavigator.loader, "anim_load");
    Viewer.start();

    try {
        gtag('event', 'page_view', {
            page_title: view.title,
            page_location: view.uri
        });
    } catch (e) {
        console.error(e);
    }
    if (view.getTab) {
        initMyTabs(view.getTab());
    }
    await _containerNavigator.add2Views(view);
    endAnimation(_containerNavigator.loader, "anim_load");

    Viewer.end();
}

class View {

    constructor(uri) {
        this._uri = uri;
    }

    get uri() {
        return this._uri;
    }

    async load(node) {
        return this.loaded;
    }

    async show(node) {
    }

    async shown(node) {

    }
}


class ListView {
    constructor(id) {
        this.uri = "view://" + id;
        this.title = id;
        this.id = id;
    }

    async show() {
    }
}

class TabView extends ListView {
    constructor(id) {
        super(id);
    }

    getTab() {
        return this.id;
    }
}



class UserView extends ListView {
    constructor(user, addClass, onclick) {
        super();
        this.user = user;
        this.prepared = prepareTemplate("user");
        this.addClass = addClass;
        this.onclick = onclick;
    }
    async load() {
        const user = this.user;
        if (typeof user == "string") {
            this.user = await loadUser(user);
        } else if (user.id && !user.name) {
            this.user = await loadUser(user.id);
        }
    }
    show() {
        const user = this.user;
        const node = this.prepared.newNode();
        const img = node.getElementsByTagName("img")[0];
        img.src = user.image;

        const name = node.getElementsByTagName("span")[0];
        name.textContent = user.name;

        node.getElementsByTagName("a")[0].onclick = (e) => { follows("POST", user), e.preventDefault() };
        node.getElementsByTagName("a")[1].onclick = (e) => { follows("DELETE", user), e.preventDefault() };

        node.userid = user.id;

        if (isMe(user.id)) {
            node.classList.add("me");
        }
        if (this.addClass) {
            node.classList.add(this.addClass);
        }

        img.onclick = name.onclick = this.onclick ? this.onclick : async (e) => {
            ProfileView.currentUserFocus = user;
            showView(new ProfileView(user));
            // showView(new FeedView(toUri("user", user.id)));

            const id = encodeURIComponent(user.id);

            // Navigator.next();
            e.preventDefault();
            e.stopPropagation();
        }
        // this.list.appendChild(node);
        return node;

    }
}

class InboxView extends TabView {

    static tabname = "hashtag";

    constructor(tag = InboxView.tabname) {
        super(tag);
    }

    async show() {
        const list = this.list;
        // await tutorial_step("tutorial_feed", list);
        await this.containerNavigator.add2Views(new ProfileView(await getMyUser()));

        const sharedUris = getSharedUris();
        const sharer = getSharer();

        const containerNavigator = this.containerNavigator;

        // const sharedViewsLink = showViewLink(sharedUris.length, containerNavigator, "Shared with you", "sharedViews");
        // sharedViewsLink.onclick = async function () {
        //     showView(new SharedView(sharedUris, sharer));
        // }


        // const prepared = prepareTemplate("user");

        // sharer.forEach(async user => {
        //     const userNode = await showUser(prepared.newNode(), user);
        //     if (userNode)
        //         sharedViewsLink.appendChild(userNode);
        // });

        // showViewLink(-1, containerNavigator, "Feed").onclick = function () {
        //     showView(new FeedView());
        //     // containerNavigator.add2Views(toActionView("invite"));
        // }

        const competencesLoaded = window.competencesLoaded || await loadFeedCompetences();
        containerNavigator.addView("competences://loaded", competencesLoaded);


        if (false) {
            console.log("InboxView", list.id);

            await readUserStream().then(stream => {

                const votes = stream.votes;
                const delegations = stream.delegations;

                const click = async function (projections, isDelegation) {
                    await showView(isDelegation ? new DelegationsView(projections) : new VotedView(projections));
                }

                showViewLink(delegations.length, containerNavigator, "Delegations").onclick = function () {
                    click(delegations, true);
                    // containerNavigator.add2Views(toActionView("invite"));
                }

                showViewLink(votes.length, containerNavigator, "Votes").onclick = function () {
                    click(votes);
                    // Navigator.add2Views(toActionView("load_dialectics", list));
                }

                // if (tutorial) {
                //     tutorial.then(tutorial => {
                //         if (!tutorial.isDone("tutorial_vote_end")) {
                //             Navigator.add2Views(toActionView("vote_tutorial", list));
                //         }
                //     })
                // };
            });

            showViewLink(-1, containerNavigator, "Votes Results").onclick = function () {
                showView(new VoteResultView());
            }
        }

    }
};

class HomeView extends TabView {

    static tabname = "mention";

    constructor(tag = HomeView.tabname) {
        super(tag);
    }

    async show() {
        // await tutorial_step("tutorial_milestones", this.list);

        // await showMilestones(this.containerNavigator);

        // this.containerNavigator.add2Views(toActionView("load_dialectics", {
        //     click: () => {
        //         showView(new FeedView());
        //     }
        // }));

        // await this.containerNavigator.add2Views(new FeedView());
        // showViewLink(user.follower_count, list, "Following:").textNode.onclick = function () {
        //     showView(new FollowingView(user));
        // }

        const loaded = window.usersLoaded || await loadFeedUsers();
        containerNavigator.addView("users://loaded", loaded);



        // const delegates = await getDelegates();


        // delegates.forEach(async delegate => {
        //     const uv = new UserView(delegate);
        //     await this.containerNavigator.add2Views(uv);
        // })

    }

}

class ProfileView extends ListView {

    constructor(user) {
        super("profile");
        this.user = user;
    }

    async load() {
        return getUserView(this.user);
    }

    async show(data) {
        const userview = await data;

        const list = this.list;
        const user = this.user;
        // const inner = view.show;
        list.classList.add("profile");

        // await tutorial_step("tutorial_competence", this.list);

        // inner(usernode);
        // (list).appendChild(usernode);
        await this.containerNavigator.add2Views(userview);


        // showViewLink(user.follower_count, list, "Following:").textNode.onclick = function () {
        //     showView(new FollowingView(user));
        // }

    }
}

class TagView extends View {

    constructor(uri, aclass) {
        super(uri);
        this.aclass = aclass;
    }

    initView() {
        const node = document.createElement("div");

        node.classList.add(this.aclass);

        const text_node = node.appendChild(document.createElement("h3"));
        const subtext_node = node.appendChild(document.createElement("smallest"));

        const tag_node = node.appendChild(document.createElement("div"));
        tag_node.classList.add("tagcloud");
        this.tag_node = tag_node;
        this.node = node;
    }

    async show(data) {

        super.show();

        data = await data;

        this.sorted = await showTagsWithPower(Object.entries(data), this.tag_node, this.onclick);

        return this.node;
    }

    async shown(node) {
        createTagCloudFromElements(this.tag_node);
    }
}

class UsersView extends TagView {

    constructor(users, uri) {
        super(uri || "users://feed", "usersview")
        this.loaded = users;
        this.onclick = (id) => {
            id = encodeURIComponent(id);
            showView(new FeedView(`/user/${id}/resources`));
        }
    }

    getContainer() {
        if (window.rightNavigator) {
            return window.rightNavigator;
        }
        return undefined;
    }

}

class CompetencesView extends TagView {

    constructor(competences, uri) {
        super(uri || "competences://feed", "competenceview")
        this.loaded = competences;
        this.onclick = (id) => {
            showView(new FeedView(`/competence/${id}/resources`));
        }
    }

    getContainer() {
        if (window.leftNavigator) {
            return window.leftNavigator;
        }
        return undefined;
    }

    async show(competences) {

        super.show(competences);

        this.tag_node.classList.add("competences");

        // const subtext_node = this.node.appendChild(document.createElement("smallest"));
        // subtext_node.textContent = document.querySelector("#load_dialectics tooltip").textContent; //Trending competences:"

        if (window.onCompetences) {
            window.onCompetences(
                this.sorted
                // Object.entries(competences).sort((a, b) => { return b[1] - a[1]; })
            )
        }
        if (window.onVIPs) {
            getDelegates().then(vips => {
                window.onVIPs(vips)
            });
        }
        return this.node;
    }
}

function applyMixins(derivedCtor, baseCtors) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null));
        });
    });
}

// applyMixins(CompetencesView, [TagView]);
// applyMixins(UsersView, [TagView]);

const loadFeedCompetences = async () => {
    const feedData = await loadFeed();
    if (feedData && feedData.length > 0) {
        if (feedData[0].uri == "competences://loaded") {
            return feedData[0].data;
        }
    }
    return { "politics": 1, "cypersecurity": 2, "ai": 3 };
}

const loadFeedUsers = async () => {
    const feedData = await loadFeed();
    if (feedData && feedData.length > 1) {
        if (feedData[1].uri == "users://loaded") {
            return feedData[1].data;
        }
    }
    return { "twitter/elonmusk": 2, "twitter/realDonaldTrump": 3, "linkedin/facebook": 1 };
}

const loadFeed = async (offset = 0, containerNavigator, feedUrl = "/feed") => {

    let url = `${feedUrl}?limit=18&offset=${offset}`;
    if ((containerNavigator && containerNavigator.focusOn)) {
        url += `&f=${containerNavigator.focusOn}`;
    }

    const feedData = await fetchAPI(url);
    return feedData;
}

class FeedView extends TabView {

    constructor(feedUrl = undefined) {
        super("feed");
        this.feedUrl = feedUrl;
    }

    offset = 0;

    async load() {
        try {
            const containerNavigator = this.containerNavigator;
            const feedData = await loadFeed(this.offset, containerNavigator, this.feedUrl);
            // this.competenceView = getCompetencesView(feedData);
            return feedData;
        } catch (e) {
            console.log(e);
        }
    }

    async show(loaded) {
        const list = this.list;
        getMyUser();

        // await showMilestones(1);

        addMainAction("createNew");

        // await Navigator.add2Views(getImgView("/media/banner.png"));

        const view = this;

        // await tutorial_step("trending", list);

        const containerNavigator = this.containerNavigator;

        //await for load and  this.competenceView = getCompetencesView(feedData);
        loaded = await loaded;

        if (this.competenceView)
            await containerNavigator.add2Views(this.competenceView);


        const loadMore = async function (feedData) {
            delete view.loaded;
            feedData = feedData ? feedData : await Viewer.loadView(view);
            console.log("feedData", feedData);
            for (let viewData of feedData) {
                //     if (viewData.data) {
                //         viewData = viewData.data;
                //     }
                await containerNavigator.addView(viewData.uri, viewData.data ? viewData.data : viewData);
            }
            view.offset += 10;
        }

        await loadMore(loaded);

        containerNavigator.onLoadMore = loadMore;
        // Navigator.next();
        // await Navigator.add2Views(new DialecticsView("/dialecticsTrending"));
        // const topics = await loadTopicViews("/topics", list, true);

        // return await competenceView.show(await competenceView.load());
    }
}


class CompetenceView extends ListView {
    constructor(competence) {
        super("competence");
        this.competence = competence;
    }
    async show() {
        // if (!isLoggedIn()) {
        //     needsLogin();
        //     return;
        // }

        // const list = document.mainContent;
        // const topics = loadTopicViews("/topicsForCompetence/" + this.competence, (list), true);
        showView(new FeedView(`/competence/${this.competence}/resources`));
    }
}

class SharedView extends ListView {
    constructor(sharedUris, sharer) {
        super("shared");
        this.sharedUris = sharedUris;
        this.sharer = sharer;
    }

    async show() {
        const list = this.list;

        await tutorial_step("tutorial_shared", list);

        const containerNavigator = this.containerNavigator;

        this.sharedUris.forEach(async uri => {
            if (uri.uri && (typeof uri.uri == "string")) {
                if (isUri("dialectic", uri.uri)) {
                    loadDialecticView(getUriId(uri), true).then((dialectic) => {
                        dialectic.onVoted = function () {
                            removeSharedUri(dialectic.uri);
                        }
                        if ((uri.user)) {
                            dialectic.userDelegatedNew = uri.user;
                        }
                    })

                } else if (isUri("topic", uri.uri)) {
                    const topic = await loadTopicView(uri, list, true);
                }

            }
        });

        for (const sharer of this.sharer) {
            console.log(sharer);
            if (isMe(sharer.id)) {
                continue;
            }
            const view = await getUserView(sharer);
            await containerNavigator.add2Views(view);
        }

        await containerNavigator.add2Views(toActionView("invite"));
    }
}

class ProjectionView extends ListView {
    constructor(uri, projections, isDelegation) {
        super(uri);
        this.projections = projections;
        this.isDelegation = isDelegation;
    }
    async show() {
        const projections = this.projections;
        const isDelegation = this.isDelegation;
        const containerNavigator = this.containerNavigator;

        projections.forEach(projection => {
            containerNavigator.add2Views({
                uri: "dialectic://" + projection.dialectic,
                load: async function () {
                    let dialectic = await loadDialecticView(projection.dialectic);
                    dialectic = await prepareDialectic(dialectic);
                    return dialectic;
                },
                show: async function (dialectic) {
                    dialectic = await dialectic;
                    if (isDelegation) {
                        const delegationTime = new Date(projection.created).getTime();
                        if (delegationTime > localStorage.getItem("lastDelegationTime") | 0)
                            localStorage.setItem("lastDelegationTime", delegationTime);
                    }
                    return await dialectic.show();
                }
            });
        });
    }
}
class DelegationsView extends ProjectionView {
    constructor(projections) {
        super("delegations", projections, true);
    }
}
class VotedView extends ProjectionView {
    constructor(projections) {
        super("voted", projections, false);
    }
}
class VoteResultView extends ListView {
    constructor() {
        super("voteresults");
    }
    async show() {

        if (!hasSubscriptionPermission()) {
            // Navigator.add2Views(toActionView("vote_sub", list));
        }
        this.containerNavigator.add2Views(toActionView("load_dialectics"));
    }
}

class TutorialView extends ListView {
    constructor(id) {
        super("tutorial");
    }

    async show() {
        const url = "/dialecticstutorial";
        tutorial_step("tutorial_vote");
        const dialectics = await loadDialecticsForUser(url);
        tutorial_step("tutorial_vote_end");
        return dialectics;
    }
}

class DialecticsView extends ListView {

    constructor(dialectics, topic) {
        super("dialectics");
        if (typeof (dialectics) == "string") {
            this.dialecticsUrl = dialectics;
        } else {
            this.dialectics = dialectics;
        }
        this.topic = topic;
    }

    async load() {
        if (!this.dialectics) {
            this.dialectics = await loadDialecticsForUser(this.dialecticsUrl);
        } else {
            this.dialectics.forEach(async dialectic => {
                if (!dialectic.root_node)
                    dialectic = await prepareDialectic(dialectic);
                // await Navigator.add2Views(dialectic);
            });
        }
        return this.dialectics;
    }

    async show() {
        const containerNavigator = this.containerNavigator;

        if (this.topic) {
            await containerNavigator.add2Views(loadTopicView(this.topic));
        }
        this.dialectics.forEach(async dialectic => {
            await containerNavigator.add2Views(dialectic);
        });
        addMainAction("createNew");
    }

}

class FollowingView extends ListView {
    constructor(user) {
        super("following");
        this.user = user;
    }
    async show() {
        const list = this.list;
        // list.classList.add("following");
        await showViewLink(this.user.follower_count, this.containerNavigator, "People you follow:");
        const followers = await loadFollowing(this.user);
        for (let follower of followers) {
            await this.containerNavigator.add2Views(new UserView(follower, "following"));
        }
        await this.containerNavigator.add2Views(toActionView("invite"));
    }
}

const showViewLink = function (count, containerNavigator, text, classname) {
    let p = document.createElement("div");
    p.appendChild(document.createElement("div")).textContent = text;
    if (count >= 0)
        p.appendChild(document.createElement("div")).textContent = count;


    // p = (attach2).appendChild(p);
    if (classname) {
        p.classList.add(classname);
    }
    p.classList.add("viewlink");
    containerNavigator.add2Views(toView(text, p));
    return p;
}

const toActionView = function (uri, params = {}) {
    return toView(uri, addAction(uri, params.add2, params.data, params.click));
}

const addMainAction = function (uri) {
    // return toActionView(uri, document.querySelector("#mainActions"));
    const mainActions = document.querySelector("#mainActions");
    mainActions.innerHTML = "";
    const view = toView(uri, addAction(uri, mainActions));
    return view;
}

const addAction = function (id, add2, data, click) {

    const wrapper = document.createElement("div");

    const original = interaction_menu.querySelector("#" + id);
    const action = original.cloneNode(true);
    action.onclick = click ? click : function (event) {
        original.onclick.apply(this, [event]);
    };
    action.data = data;


    action.classList.add("inner_card");
    wrapper.appendChild(action);

    const tooltipText = action.querySelector("tooltip");
    if (tooltipText) {
        const tootltip = document.createElement("tooltip");
        tootltip.textContent = tooltipText.textContent; //+ ": ";
        wrapper.appendChild(tootltip);
    }

    if (add2) {
        return add2.appendChild(wrapper);
    } else {
        return wrapper;
    }
}

const toView = function (uri, node) {
    return {
        uri: "view://" + uri,
        load: function () {
            return node;
        },
        show: function (view) {
            return (view ? view : node);
        }
    }
}

const getViewNode = async function (view) {
    const loaded = view.load();
    view.loaded = loaded;
    const node = view.show(loaded);
    return node;
}


const getPreView = async function (url, mode) {

    const topicview = await loadTopicView(url);

    let users = ["Gemini@" + SYSTEM_USER_DOMAIN, "Grok@" + SYSTEM_USER_DOMAIN, "ChatGPT@" + SYSTEM_USER_DOMAIN];
    const view = {
        uri: "preview://" + topicview.uri,
        load: async function () {

            const loaded = await topicview.load({ previewDialectics: false });
            topicview.loaded = loaded;

            this.topicview = topicview;
            return loaded;

        },
        show: async function (loaded) {

            loaded = await loaded;

            await this.topicview.show(loaded);

            const topic = this.topicview.loaded;
            const text = topic.summary || topic.title;

            const prepared = prepareTemplate("preview-view");
            const node = prepared.newNode();

            node.classList.add("mode" + mode);

            node.querySelector(".bk-image img").src = topic.image;
            // node.querySelector(".logo").src = "/media/logo.svg";

            node.querySelector(".text").textContent = text;
            node.querySelector(".cta").textContent = "Make sure AI stays aligned!";

            // topic.previewDialectics[0].statements.forEach(statement => {
            //     const div = document.createElement("li");
            //     div.textContent = "-" + statement.value;
            //     node.querySelector(".statements").appendChild(div);
            // });

            const competences = node.querySelector(".competences");
            competences.innerHTML = "";
            competences.insertBefore(document.createElement("background"), competences.firstChild);

            await showCompetences(competences, topic.competences);
            competences.appendChild(document.createElement("space"));

            const userContainer = node.querySelector(".users");
            userContainer.innerHTML = "";
            userContainer.appendChild(document.createElement("background"));


            function randomIntFromInterval(min, max) { // min and max included 
                return Math.floor(Math.random() * (max - min + 1) + min);
            }

            let bars = new Array();
            let sum = 0;

            await asyncForEach(users, () => {
                // randomIntFromInterval();
                const bar = appendBar(randomIntFromInterval(9, 81));
                sum += bar.value;
                bars.push(bar);
                // bar.style.width = Math.random() * 100 / 3 + "vw";
            });

            bars = bars.sort((a, b) => b.value - a.value);
            const you = await getAnonymousUserNode();
            // bars[0].appendChild(you);

            shuffleArray(users);

            await asyncForEach(users, async (user, index) => {
                const view = await getUserView(user);
                const vn = await getViewNode(view);
                const bar = bars[index];
                bar.style.width = bar.value * 100 / sum / 1.5 + "vw";
                vn.appendChild(bar);
                if (index == 0)
                    vn.appendChild(you);
                userContainer.appendChild(vn);
            })

            return node
        }
    };
    return view;
}

function hashCode(string) {
    var hash = 0;
    for (var i = 0; i < string.length; i++) {
        var code = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}



const shuffleArray = function (array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}


const delegateCompetence = async (competence, user) => {
    const userActionResponse = await fetchAPI("/competence/" + competence, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({ userid: user })
    });
    handleUserActionResponse(userActionResponse);
    return handleUserActionResponse;
}

class ResizableDiv {
    constructor(handle, resizableDiv, initialWidthPercent, cb) {
        this.handle = handle;
        this.resizableDiv = resizableDiv;
        this.cb = cb; // Optional callback function

        this.isDragging = false;
        this.initialX = 0;
        this.initialWidthPercent = 0; // Store initial width as a percentage
        this.resizableDiv.style.width = `${initialWidthPercent}%`;
        if (this.cb) {
            this.cb(parseFloat(initialWidthPercent)); // Call callback with new width as percentage (optional)
        }
        this.handle.addEventListener('mousedown', this.onMouseDown.bind(this));
        this.handle.addEventListener('touchstart', this.onTouchStart.bind(this));

        this.handle.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();
            return true;
        });

    }

    onMouseDown(event) {
        this.isDragging = true;
        this.initialX = event.clientX;
        this.initialWidthPercent = parseFloat(this.resizableDiv.style.width) || 0; // Get initial width as percentage

        this.handle.addEventListener('mousemove', this.onMouseMove.bind(this));

        event.stopPropagation();
        event.preventDefault();
        return true;
    }

    onMouseMove(event) {
        if (this.isDragging) {
            const deltaX = event.clientX - this.initialX;
            const parentWidth = this.handle.parentElement.offsetWidth; // Get parent width
            const newWidthPercent = Math.max(0, (this.initialWidthPercent + (deltaX / parentWidth) * 100)); // Calculate new width as percentage
            this.resizableDiv.style.width = `${newWidthPercent}%`;
            this.widthPercent = newWidthPercent;
            this.widthPercent = newWidthPercent;
            if (this.cb && newWidthPercent <= 100) {
                this.cb(parseFloat(this.widthPercent)); // Call callback with new width as percentage (optional)
            }

            if (!this.endListenerFunction) {
                this.endListenerFunction = this.onMouseUp.bind(this);
                document.addEventListener('mouseup', this.endListenerFunction);
            }
        }
    }

    onMouseUp() {
        this.isDragging = false;
        this.cleanupEventListeners();
        if (this.cb) {
            this.cb(parseFloat(Math.min(100, this.widthPercent)), true); // Call callback with new width as percentage (optional)
        }
    }

    onTouchStart(event) {
        this.isDragging = true;
        this.initialX = event.touches[0].clientX;
        this.initialWidthPercent = parseFloat(this.resizableDiv.style.width) || 0; // Get initial width as percentage

        // Add touchend listener to the parent element
        this.handle.addEventListener('touchmove', this.onTouchMove.bind(this));

        event.stopPropagation();
        event.preventDefault();
        return true;
    }

    onTouchMove(event) {
        if (this.isDragging) {
            const deltaX = event.touches[0].clientX - this.initialX;
            const parentWidth = this.handle.parentElement.offsetWidth;
            const newWidthPercent = Math.max(0, (this.initialWidthPercent + (deltaX / parentWidth) * 100));
            this.resizableDiv.style.width = `${newWidthPercent}%`;
            this.widthPercent = newWidthPercent;
            if (this.cb && newWidthPercent <= 100) {
                this.cb(parseFloat(this.resizableDiv.style.width)); // Call callback with new width as percentage (optional)
            }

            if (!this.endListenerFunction) {
                this.endListenerFunction = this.onTouchEnd.bind(this);
                document.addEventListener('touchend', this.endListenerFunction);
            }
        }
    }

    onTouchEnd() {
        this.isDragging = false;
        this.cleanupEventListeners();
        if (this.cb) {
            this.cb(parseFloat(Math.min(100, this.widthPercent)), true); // Call callback with new width as percentage (optional)
        }
    }

    cleanupEventListeners() {
        this.handle.removeEventListener('mousemove', this.onMouseMove);
        this.handle.removeEventListener('touchmove', this.onTouchMove);
        document.removeEventListener('mouseup', this.endListenerFunction);
        document.removeEventListener('touchend', this.endListenerFunction);
        this.endListenerFunction = null;
    }
}

function addAlpha(color, opacity) {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

class FibonacciMapper {
    static fibonacciNumbers = [1, 1, 2, 3, 5, 8, 13, 21];

    static fibonacciToPercentage(fibNumber) {
        const index = this.fibonacciNumbers.indexOf(fibNumber);
        const totalNumbers = this.fibonacciNumbers.length;
        return (index + 1) / totalNumbers * 100;
    }

    static percentageToFibonacci(percentage) {
        const totalNumbers = this.fibonacciNumbers.length;
        const index = Math.round(percentage / 100 * totalNumbers) - 1;
        return this.fibonacciNumbers[index];
    }
}

class MetricsView extends View {
    constructor(metricsdata, uri) {
        super(uri);
        this.metricsdata = metricsdata;
    }

    async show() {
        const metricsdata = await this.metricsdata;
        const node = document.createElement("div");

        const trafficLights = cloneNode("traffic-lights");
        node.appendChild(trafficLights);
        // node.innerText = JSON.stringify(metricsdata);

        const greenLight = trafficLights.querySelector('.green');
        const yellowLight = trafficLights.querySelector('.yellow');
        const redLight = trafficLights.querySelector('.red');

        function updateTrafficLight(pastAverage) {
            greenLight.classList.add('light-off');
            yellowLight.classList.add('light-off');
            redLight.classList.add('light-off');

            if (pastAverage < 3) {
                greenLight.classList.remove('light-off');
            } else if (pastAverage < 5) {
                yellowLight.classList.remove('light-off');
            } else {
                redLight.classList.remove('light-off');
            }
        }

        updateTrafficLight(metricsdata.pastAverage);

        const indicator = document.createElement("div");
        indicator.classList.add("arrow-indicator");
        node.appendChild(indicator);


        function getArrowAndColor(percentageChange) {
            if (percentageChange > 10) {
                return { arrow: '↑', color: 'red' };
            } else if (percentageChange > 3) {
                return { arrow: '↗', color: 'orange' };
            } else if (percentageChange > 0) {
                return { arrow: '-', color: 'yellow' };
            } else if (percentageChange < -10) {
                return { arrow: '↓', color: 'green' };
            } else if (percentageChange < -3) {
                return { arrow: '↙', color: 'lightgreen' };
            } else {
                return { arrow: '-', color: 'yellow' };
            }
        }

        function updateArrowIndicator(percentageChange) {
            const { arrow, color } = getArrowAndColor(percentageChange);
            indicator.textContent = arrow;
            indicator.style.color = color;
        }

        updateArrowIndicator(metricsdata.percentageChange);

        return node;
    }
}


class RiskView extends View {
    constructor(risk) {
        super("risk://" + risk.id);
        this.risk = risk;
    }

    async load(node) {
        return this.risk;
    }

    async show(node) {
        const prepared = prepareTemplate("risk");
        node = prepared.newNode();

        const competences = node.querySelector(".competences");
        await showCompetences(competences, this.risk.competences);

        // const attach2 = node.querySelector(".projection-targets");
        // showDelegates(attach2, this.risk.affected);

        const colorCb = (bar, value) => {
            let nvalue = parseFloat(value) / 100;

            const color = percentageToHsl(nvalue, 120, 0);
            // bar.parentNode.style.backgroundColor = "#" + addAlpha(hslToHex(color), 0.3);
            // bar.parentNode.style.setProperty('--backbar-color', color);
            node.style.setProperty('--backbar-color', color);
            // const bg = "#" + addAlpha("000000", nvalue);

            const bg_f = extrapolate(nvalue, 0.81, 0.81, "faster");
            // const fg_f = extrapolate(nvalue, 0.81, 0.21, "slower");

            const fg = blendColor("#000000", bg_f, true);
            // console.log(bg, fg, bg_f);
            // const aaa = nvalue > 0.65 ? "white" : "black";
            node.style.setProperty('--risk-color', fg);
            node.style.setProperty('--accent-color', color);
            // const bg = blendColor("#FFFFFF", bg_f);
            // node.style.backgroundColor = bg;
        }

        const handleDrag = (container, bar, startValue) => {
            new ResizableDiv(container, bar, startValue, (value, changed) => {
                colorCb(bar, value);
                const fbn = FibonacciMapper.percentageToFibonacci(value);
                this.risk.score = fbn;
                this.changed = changed;
                if (changed) {
                    if (this.timeout_detailed) {
                        window.clearTimeout(this.timeout_detailed);
                    }
                    this.timeout_detailed = window.setTimeout(function () {
                        node.classList.add("detailed");
                        if (this.timeout_detailed) {
                            window.clearTimeout(this.timeout_detailed);
                        }
                        this.timeout_detailed = window.setTimeout(function () {
                            node.classList.remove("detailed");
                        }, 4500);
                    }, 300);
                }
            });
        };

        const maxRiskEstimateScore = 21;

        // <img class="traffic-light" src="path/to/your/traffic-light.png" alt="Traffic Light">

        const addBar = (value, container) => {
            const percent = value / maxRiskEstimateScore * 100;
            appendBar(100, container, () => { }).classList.add("barBack");
            const mbar = appendBar(percent, container, colorCb);
            mbar.classList.add("barFront");
            mbar.appendChild(cloneNode("traffic-lights")).classList.add("traffic-light");
            handleDrag(container, mbar, percent);
        }

        const riskContainer = node.querySelector(".risk");
        addBar(this.risk.score, riskContainer);
        // riskContainer.textContent = this.risk.risk;

        const riskText = riskContainer.appendChild(document.createElement("div"));
        riskText.classList.add("riskText");
        riskText.appendChild(document.createElement("tag")).textContent = "⋮" + this.risk.tag; // + ":";
        riskText.appendChild(document.createElement("text")).textContent = this.risk.descr;

        const industry = node.querySelector(".industry");
        industry.textContent = this.risk.industries.map(el => {
            if (el.indexOf("?label=") > 0) {
                el = el.split("?label=")[1];
            }
            return decodeURIComponent(el);
        }).join(", ");
        industry.onclick = (evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            const targetElement = evt.currentTarget;
            // Create a new div element for the box
            try {
                document.body.removeChild(document.getElementById("hoverbox"));
            } catch (e) {
            }

            const box = document.body.appendChild(document.createElement('div'));
            box.id = "hoverbox";

            // Create a text element for the box content
            // const text = document.createTextNode(this.risk.industries.map(el => "#" + el.reason).join("\n"));
            // box.appendChild(text);

            // Position the box beside the target element (adjust as needed)
            // box.style.position = 'absolute';
            // box.style.top = targetElement.offsetTop + 'px';
            // box.style.left = targetElement.offsetLeft + targetElement.offsetWidth + 10 + 'px'; // Adjust the horizontal offset as needed

            box.onclick = (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                document.body.removeChild(box);
                return false;
            }
            return false;
        }

        const scenarios = node.querySelector(".scenarios");


        const bottomText = node.querySelector(".bottomText");

        const addTextNode = (intro, cl, tc, textclass) => {

            const textNode = bottomText.appendChild(document.createElement("div"));

            textNode.appendChild(document.createElement("intro")).textContent = intro;

            if (!tc.length) {
                tc = [tx];
            }
            tc.forEach(tc => {
                let tn = textNode.appendChild(document.createElement("text"));
                tn.textContent = tc;
                if (textclass) {
                    tn.classList.add(textclass);
                }
            });
            // textNode.appendChild(document.createElement("text")).innerHTML = tc;

            textNode.classList.add(cl);
        }


        if (this.risk.scenarios) {
            addTextNode("scenarios:", "scenario", this.risk.scenarios.map(scenario => "-" + scenario.text));
        }

        addTextNode("affected?", "affected", this.risk.affected.map(affected => "" + affected.split("/")[1]), "tag");
        addTextNode("mitigate?", "delegated", this.risk.delegate.map(delegate => "" + delegate.split("/")[1]), "tag");

        // addTextNode("reason", this.risk.industry.reason);
        // console.log(this.risk);

        super.show(node);

        // this.containerNavigator.add2Views(loadTopicView(this.risk.topics[0]));

        return node;
    }
}

class MilestoneView extends View {

    constructor(milestone, readyWhen) {
        super("milestone://" + milestone.id)
        this.milestone_type = milestone.id;
        // this.milestone = milestone;
        this.readyWhen = readyWhen || [];
    };


    get milestone() {
        return getMilestone(this.milestone_type)
    };

    get uri() { return "milestone://" + this.milestone_type };

    showDetails = true || window.preview;


    get call2ActionReady() {
        const dd = this.readyWhen;
        let ready = true;
        dd.forEach((attr) => {
            if (ready) {
                const value = this.attachedNode.getAttribute(attr);
                if (!value) {
                    ready = false;
                }
            }
        });
        return ready;

        // const delegated = this.attachedNode.getAttribute("delegated");
        // const competence = this.attachedNode.getAttribute("competence");
        // return !!delegated && !!competence;
    }

    onAttribute(attr, value) {
        this.attachedNode.setAttribute(attr, value);
        this.attachedNode.querySelector("[cta_actions=" + attr + "]").classList.remove("cta_actions");
        const ready = this.call2ActionReady;
        this.attachedNode.setAttribute("ready", ready);
        console.log(attr, value, ready);
        if (ready) {
            blink(this.attachedNode.querySelector(".calltoaction"));
        }
    };

    async load(node) {
    }

    async show(node) {
        const progress = node.querySelector(".progress");
        const setAttribute = progress.setAttribute;

        const view = this;
        progress.setAttribute("transition", true);

        progress.setAttribute = (key, value) => {
            if (key != "progress") {
                setAttribute.call(target, key, value);
            }
            else {
                var val = parseInt(value);
                var circle = progress.querySelector('.progressbar');

                if (isNaN(val)) {
                    val = 100;
                }
                else {
                    var pr = circle.getAttribute('r');
                    var r = progress.clientWidth * 0.6;
                    // r = 50;
                    var c = Math.PI * (r * 2);

                    if (val < 9) { val = 9; }
                    if (val > 100) { val = 100; }

                    // val = 100 - val;

                    // var pct = (((100 - val) / 100) * c);
                    var pct = val * c / 100;
                    pct = pct / progress.clientWidth * 100

                    // circle.setAttribute("style", `stroke-dashoffset:${pct}px`);
                    // circle.style[`stroke-dashoffset:${pct}px`];
                    circle.style.strokeDasharray = `${pct}% 999`;

                    // console.log("set progress:", value, pct, circle, circle.style.strokeDashoffset);
                }
            }
        };



    };

    async shown(node) {
        const progress = node.querySelector(".progress");
        // let milestones = window.milestones;
        // if (milestones) {
        //     milestones = milestones.reduce(function (map, obj) {
        //         map[obj.id] = obj;
        //         return map;
        //     }, {});
        progress.setAttribute("progress", this.milestone.progress);

        if (this.milestone.progress >= 100) {
            window.setTimeout(() => {
                node.classList.add("anim_change_stroke_color");
                node.classList.add("complete");
                blink(node);
            }, 1200)
        }

        this.attachedNode.setAttribute("ready", this.call2ActionReady);

        const view = this;

        const ondetail = async (evt) => {
            node.classList.toggle("detailed");
            if (view.detail && node.classList.contains("detailed") >= 0) {
                await view.detail(node);
                // view.detail = undefined;
            }
        };

        // console.log("shown milestone", this, this.attachedNode, this.showDetails);

        if (this.showDetails) {
            node.classList.remove("detailed");
            await ondetail();
        } else {
            node.onclick = ondetail;
        }

    };

    async detail(node) {
        const cta = node.querySelector(".calltoaction");
        cta.onclick = async (evt) => {
            try {
                if (this.call2ActionReady) {
                    load();
                    await this.call2Action();
                    load(true);
                } else {
                    blink(node.querySelectorAll(".cta_actions"));
                }
            } catch (e) {
                console.error(e);
            } finally {
                evt.preventDefault();
                evt.stopPropagation();
                return false;
            }
        }
        blink(cta);
    }
}

class VipMatchMilestoneView extends MilestoneView {

    constructor(milestone) {
        super(milestone);
        // this.users = milestone.data.vips;
    }
    async show() {
        const prepared = prepareTemplate("vipmatch-milestone-view");
        const node = prepared.newNode();

        // const textNode = node.querySelector(".text");
        // textNode.textContent = text;

        const imgs = node.querySelectorAll("image");
        if (false) {
            await asyncForEach(this.users, async (user, index) => {
                if (index >= 2) {
                    return;
                }
                user = getUserObject(user);
                user = await (loadUserObject(user));
                const img = imgs[index];
                img.setAttributeNS('http://www.w3.org/1999/xlink', 'href', user.image);
            });
        }
        super.show(node);
        // MilestoneView.prototype.show.call(this, node);
        return node
    };

    async detail(node) {
        super.detail(node);
        let sum = 0;

        const matches_container = node.querySelector(".matchcontainer");
        const matches = (this.milestone.result || [{ vip_name: "?", alignment: 30 }, { vip_name: "?", alignment: 60 }, { vip_name: "?", alignment: 90 }])
            .filter(el => {
                if (!el.alignment) {
                    return false;
                }
                sum += el.alignment;
                return true;
            })
            .sort((a, b) => {
                return b.alignment - a.alignment;
            });
        await asyncForEach(matches, async (match, index) => {
            const match_node = document.createElement("div");
            match_node.textContent = match.vip_name;
            match_node.style.fontSize = 0.5 + match.alignment / sum + "em";
            appendBar(match.alignment / sum * 100, match_node);
            matches_container.appendChild(match_node);
        });

        const delegates = await getDelegates();
        const attach2 = node.querySelector(".projection-targets");
        showDelegates(attach2, delegates);

    }

    call2Action() {
        showView(new FeedView());
    }
}

class CompetenceMilestoneView extends MilestoneView {

    constructor(milestone) {
        super(milestone);
    }
    async show() {
        const prepared = prepareTemplate("competence-milestone-view");
        const node = prepared.newNode();
        super.show(node);

        const geos = ["jp", "in", "il", "se", "br", "ar", "cn", "es", "ch", "it", "ca", "de", "us", "fr"];

        const cultures = node.querySelector(".cultures");
        cultures.innerHTML = "";

        if (window.feedCompetences) {
            showCompetenceMap(Object.entries(window.feedCompetences), node.querySelector(".competences"));
        }

        shuffleArray(geos).forEach(geo => {
            cultures.appendChild(document.createElement("img")).src = `/media/flags/${geo}.svg`;
        })
        // cultures.appendChild(document.createElement("shadow")).classList.add("right");

        // const competences = await getMyCompetences();
        // showCompetenceMap(competences, node.querySelector(".tagcloud"));

        return node
    };

    async detail(node) {
        super.detail(node);
    }

    call2Action() {
        Sharer.share({ uri: "user://" + getMyUser().id });
    }
}

class DelegateCompetenceMilestoneView extends MilestoneView {

    constructor(milestone) {
        super(milestone, ["delegated", "competence"]);
    }
    async show() {
        const prepared = prepareTemplate("delegate-competence-milestone-view");
        const node = prepared.newNode();
        super.show(node);
        // addAction("share", node.querySelector(".calltoaction"))
        return node
    };

    async detail(node) {

        const containerNavigator = this.containerNavigator;

        super.detail(node);

        const delegates = await getDelegates();

        const cm = node.querySelector(".tagcloud");
        const attach2 = node.querySelector(".projection-targets");

        showDelegates(attach2, delegates, (e) => {
            const athis = this;
            containerNavigator.selectDelegate({ delegates: delegates }, (delegated) => {
                this.onAttribute("delegated", delegated.id);
                showDelegates(attach2, delegated, athis);
                cm.classList.remove("hidden");
                blink(cm);
            });
        });

        const competences = await getMyCompetences();
        showCompetenceMap(competences, cm, (competence) => {
            this.onAttribute("competence", competence);
        });
    };



    async call2Action() {
        const node = this.attachedNode;
        const delegated = node.getAttribute("delegated");
        const competence = node.getAttribute("competence");
        await delegateCompetence(competence, delegated);
        return true;
    }
}

const getAlignmentView = function (users, text) {

    const view = {
        uri: "alignmentView://" + hashCode(users.join(",") + text),
        load: async function () {
        },
        show: async function () {

            const containerNavigator = this.containerNavigator;

            const prepared = prepareTemplate("alignment-view");
            const node = prepared.newNode();

            const textNode = node.querySelector(".text");
            textNode.textContent = text;

            const svg = node.querySelector("svg");
            if (!users || users.length == 0) {
                svg.style.display = "none";
            } else {
                const userContainers = svg.querySelectorAll(".usercontainer");

                const addUser = async function (user, index) {
                    const view = await (getUserView(user));
                    const vn = await getViewNode(view);
                    const userContainer = userContainers[index];
                    userContainer.appendChild(vn);
                    console.log("add user", user, index);
                }

                await addUser(await getMyUser(), 0);

                await asyncForEach(users, async (user, index) => {
                    user = getUserObject(user);
                    await addUser(user, index + 1);
                });
            }

            node.onclick = () => {
                containerNavigator.align(users);
            }

            return node
        }
    };
    return view;
}

const getImgView = async function (imageurl) {

    const view = {
        uri: "image://" + imageurl,

        load: function () {
        },

        show: function () {
            const prepared = prepareTemplate("img-view");
            const node = prepared.newNode();

            const img = node.querySelector("img");
            img.src = imageurl;

            return node
        }
    };
    return view;
}

const getEyeView = function (imageurl, centerUrl) {

    const view = {
        uri: "eye://" + imageurl ? imageurl : "" + centerUrl ? centerUrl : "",

        load: function () {
        },

        show: function () {
            const prepared = prepareTemplate("eye-view");
            const node = prepared.newNode();

            const img = node.querySelector(".image img");

            if (imageurl)
                img.src = imageurl;

            if (centerUrl)
                node.querySelector(".center").src = centerUrl;;

            return node
        }
    };
    return view;
}

const getUserObject = function (user) {
    if (!user || (user == "me" && !isLoggedIn())) {
        user = getAnonymousUser();
    } else if (!user || (user == "any")) {
        user = getPlaceholderUser();
    }
    else {
        user = typeof user == "string" ? { id: user } : user;
    }
    return user;
}

const loadUserObject = async function (user) {
    if (typeof user == "string") {
        user = await loadUser(user);
    } else if (user.id && !user.image && user.id != "any") {
        user = await loadUser(user.id);
    }
    return user;
}

const getUrlView = async function (url) {

    const view = {
        uri: "url://" + typeof url == "string" ? url : url.uri,
        load: async function () {
            if (typeof url == "string") {
                return fetch(url);
            }
            return url;
        },
        show: async function (data) {
            data = await data.then((res) => {
                return res.text();
            }).then(html => {
                // Initialize the DOM parser
                const parser = new DOMParser()
                const doc = parser.parseFromString(html, "text/html")
                // console.log(doc.documentElement);
                return doc.documentElement;
            }).catch(error => {
                console.error('Failed to fetch page: ', error)
            })
            return data;
        }, mode: user
    };

    return view;
}

const getUserView = async function (user) {
    user = await user;
    user = getUserObject(user);

    const view = {
        uri: "user://" + user.id,
        load: async function () {
            const prepared = prepareTemplate("user");
            const usernode = await showUser(prepared.newNode(), user);
            if (usernode) {
                if (user.id != "any") {
                    const cm = usernode.querySelector(".tagcloud");
                    const competences = await getCompetences(user);
                    await showCompetenceMap(competences, cm);
                }
                if (isMe(user.id)) {
                    usernode.classList.add("me");
                }
            }
            return usernode
        },
        show: async function (usernode) {
            usernode = await usernode;
            // if (!isMe(user.id)) {
            //     Navigator.setMode("user");
            // }
            return usernode;
        }, mode: user
    };
    // Navigator.add2Views(view);
    return view;
}

const showCompetenceMap = async function (competences, cm, onclick) {
    cm.innerHTML = "";
    if (!onclick) {
        onclick = (tag) => {
            containerNavigator.competence(tag);
        }
    }
    return await showTagsWithPower(competences, cm, onclick);
}

const showTagsWithPower = async function (tags, attach2, onclick) {
    let power_sum = 0;
    let nodes = [];
    const template = prepareTemplate("tag");

    for (let tag of tags) {
        const tagnode = template.newNode();

        let fullText = tag[0];
        let tagicon = tagnode.querySelector(".tagicon");

        if (fullText.startsWith("twitter/")) {
            fullText = fullText.replace("twitter/", "");
            tagicon = cloneNode("xicon", tagicon);
        } else if (fullText.startsWith("linkedin/")) {
            fullText = fullText.replace("linkedin/", "");
            tagicon = cloneNode("linkedinicon", tagicon);
        } else {
            tagicon.style.display = "none";
        }

        tagicon.classList.add("tagicon");

        tagnode.querySelector(".text").textContent = fullText;

        tagnode.tag = tag[0];
        tagnode.power = parseInt(tag[1]);
        tagnode.setAttribute("tag", tagnode.tag);

        tagnode.onclick = function (evt) {
            const selected = attach2.getAttribute("tag");
            if (selected) {
                const selected_competence = attach2.querySelector(`[tag='${selected}']`);
                if (selected_competence) {
                    selected_competence.classList.remove("selected");
                }
            }

            attach2.setAttribute("tag", tagnode.tag);

            tagnode.classList.add("selected");

            onclick(tagnode.tag, tagnode);

            evt.preventDefault();
            evt.stopPropagation();
            return false;
        };
        nodes.push(tagnode);

        power_sum += parseInt(tagnode.power);
    }

    nodes.sort((a, b) => {
        return b.power - a.power;
    });

    if (nodes.length > 0) {
        nodes = nodes.slice(0, Math.min(nodes.length, 36));
        let maxZIndex = nodes.length;
        const maxPercent = (nodes[0].power / power_sum).toFixed(2);

        nodes.forEach(node => {
            const relativePower = node.power / power_sum;

            // Calculate font size based on power and screen size
            const baseFontSize = 36; // Maximum font size
            const viewportFontSize = Math.min(9 * window.innerHeight / 100, 6 * window.innerWidth / 100); // 9vh or 9vw
            const calculatedFontSize = Math.min(baseFontSize, viewportFontSize) * (0.30 + relativePower * 1.8);

            // Calculate padding based on font size.
            const basePadding = 1.2;
            const padding = basePadding * (calculatedFontSize / 3); // Adjust padding relative to font size.

            node.style.fontSize = `${calculatedFontSize}px`; // Use pixels for consistent size
            node.style.padding = `${padding}px`;
            node.style.opacity = (1 - (maxPercent - relativePower.toFixed(2))).toString();
            node.style.zIndex = maxZIndex--;
            node.style.display = "inline-block";
            attach2.appendChild(node);

            if (node.offsetWidth > attach2.offsetWidth) {
                node.style.display = "block"; // Force break
            }

        });
    }
    return nodes;
};

window.onhashchange = async function (e) {
    await containerNavigator.onHashChange();
}

try {
    window.user = getStoredJSONObject("user");
    if (!window.user.id) {
        throw ("user not initialized correctly: ", window.user);
    }
} catch (e) {
    delete window.user;
    console.error(e);
}


// require('./auth.js');
// require('./messaging.js');



// const perf = initFirebase(() => { return getPerformance(app) });


// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const messaging = getMessaging(app);
// const perf = getPerformance(app);

// import { getAnalytics, setUserProperties, setAnalyticsCollectionEnabled } from "https://www.gstatic.com/firebasejs/10.7.2/firebase-analytics.js";
// const analytics = getAnalytics(app);

// if (window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1")
//     setAnalyticsCollectionEnabled(analytics, true);

const setUserProperties = (prop) => {
    for (const key in prop) {
        gtag('set', key, prop[key]);
    }
}




const registerServiceWorker = async () => {

    if ("serviceWorker" in containerNavigator) {
        try {

            const registrations = await containerNavigator.serviceWorker.getRegistrations();

            for (const registration of registrations) {
                await registration.unregister();
            }

            const registration = await containerNavigator.serviceWorker.register("service-worker.js", {
                scope: "/",
            });
            if (registration.installing) {
                console.log("Service worker installing");
            } else if (registration.waiting) {
                console.log("Service worker installed");
            } else if (registration.active) {
                console.log("Service worker active");
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
};

function showNotification(notificationData) {
    //if(document.visibilityState === "visible") {
    //  return;
    //   }
    const title = notificationData.title;
    const body = notificationData.body;
    const icon = notificationData.icon;
    const notification = new Notification(title, { body, icon });
    notification.onclick = () => {
        notification.close();
        window.parent.focus();
    }
}

const hasSubscriptionPermission = function () {
    return Notification.permission === "granted";
}

const create = async function () {
    if (!isLoggedIn()) {
        needsLogin();
        return;
    }
    containerNavigator.setMode("create");
    containerNavigator.pending_action = async () => {
        const userinput = document.querySelector("#create textarea").value;
        const dialectic = await fetchAPI("/dialectic/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ input: userinput })
        });
        showView(new DialecticsView([dialectic]));
    }
    // Navigator.create();
}

const initMenu = async () => {

    const interaction_menu = document.querySelector("#interaction_menu");

    // interaction_menu.querySelector('#susi').onclick = function () {
    //     Navigator.susi();
    // };
    interaction_menu.querySelector('#share').onclick = function (e) {
        Sharer.share(this);
        e.preventDefault();
        e.stopPropagation();
    };
    interaction_menu.querySelector('#invite').onclick = function (e) {
        Sharer.invite();
        e.preventDefault();
        e.stopPropagation();
    };
    interaction_menu.querySelector('#delegateInvite').onclick = function (e) {
        Sharer.invite();
        e.preventDefault();
        e.stopPropagation();
    };
    interaction_menu.querySelector('#createNew').onclick = function (e) {
        create();
        e.preventDefault();
        e.stopPropagation();
    };
    interaction_menu.querySelector('#vote').onclick = function () {
        containerNavigator.callPending();
    };
    interaction_menu.querySelector('#vote_sub').onclick = function () {
        containerNavigator.subscribe();
    };
    interaction_menu.querySelector('#send').onclick = function () {
        containerNavigator.callPending();
    };
    interaction_menu.querySelector('#end').onclick = async function () {
        if (!isLoggedIn()) {
            needsLogin();
            return;
        }
        localStorage.setItem("tutorial_done", true);
        containerNavigator.cancel();
        containerNavigator.next();
    };

    // interaction_menu.querySelector('#delegating').onclick = async function () {
    //     const dialectic = Navigator.getCurrentView();
    //     const delegate = dialectic.userDelegatedNew;

    //     await fetchAPI("/projection/" + Navigator.getCurrentView().uri, {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         method: "POST",
    //         body: JSON.stringify({ target: delegate.id })
    //     });
    //     dialectic.userDelegated = delegate;
    // };

    interaction_menu.querySelector('#cancel').onclick = function () {
        containerNavigator.cancel();
    };

    interaction_menu.querySelector('#next').onclick = function () {
        containerNavigator.next();
    };



    const menu = document.querySelector("#menu");
    const info_menu = document.querySelector('#nav');

    menu.onclick = function () {
        info_menu.style.display = info_menu.style.display === "block" ? "none" : "block";
    };

    info_menu.querySelectorAll('a[docuri]').forEach(a => {
        a.onclick = function () {
            info(a);
        };
    });

    info_menu.querySelectorAll('#reset_tutorial').forEach(a => {
        a.onclick = function (e) {
            resetTutorial(e);
        };
    });

    info_menu.querySelectorAll('#logout').forEach(a => {
        a.onclick = function (e) {
            window.signOut().then(() => {
                needsLogin();
                setStoredJSON("user", null);
            })
        };
    });

    info_menu.querySelectorAll('#deleteAccount').forEach(a => {
        a.onclick = function (e) {
            window.deleteAccount().then(() => {
            })
        };
    });
}

const info = (node) => {
    const uri = node.getAttribute("docuri");
    const src = "https://docs.google.com/document/u/0/d/e/" + uri + "/pub?embedded=true";
    window.location.href = src;
}

const vhToPixels = (vh) => {
    return Math.round(window.innerHeight / (100 / vh));
}

const tutorial_step = async (step, append2) => {
    if (!window.preview && window.tutorial) {

        if (!step.startsWith("tutorial_")) {
            step = "tutorial_" + step;
        }
        if (!append2) {
            append2 = containerNavigator.container;
        }
        await window.tutorial.then(tutorial => {
            if (tutorial.isDone(step)) {
                return;
            }

            const step_node = tutorial.querySelector("#" + step).cloneNode(true);
            step_node.querySelectorAll(".hideTutorialHint").forEach(h => {
                h.onclick = async function () {
                    await fadeOut(step_node);
                    tutorial.done(step);
                    step_node.parentNode.removeChild(step_node);
                    if (step_node.onDone) {
                        step_node.onDone();
                    }
                }
            });
            if (!append2) {
                step_node.show = () => {
                    containerNavigator.setMode(step);
                    Viewer.setView((null, step_node, containerNavigator.container));
                };
                containerNavigator.add2Views(step_node);
            } else {
                append2.appendChild(step_node);
            }
            return step_node;
        });
    }
    return null;
}


const tresholdLoad = vhToPixels(20) / 3;

// callback is called on intersection change
function onIntersection(entries, opts) {
    entries.forEach(async entry => {
        if (window.scollStopped) {
            window.scrollTo(0, 0);
            return;
        }
        const scrollTop = document.mainContent.parentNode.scrollTop;
        // console.log("viewed", entry.isIntersecting, scrollTop, tresholdLoad);
        const significant = true; //scrollTop > tresholdLoad;

        if (containerNavigator.loadMore && entry.isIntersecting && significant) {

            if (Viewer.oneView) {
                window.scrollTo(0, 0);
                window.onscroll = function (e) {
                    e.preventDefault();
                }
                window.scollStopped = true;
                containerNavigator.container.innerHTML = "";

                document.body.style.overflow = 'hidden';

                window.setTimeout(() => {
                    window.scollStopped = false;
                    window.onscroll = null;
                    document.body.style.overflow = 'auto';
                    window.scrollTo(0, 0);
                }, 300);
            }

            console.log(scrollTop, tresholdLoad);

            await containerNavigator.loadMore();

        }
    });
}

export const resetTutorial = function (e) {
    localStorage.removeItem("tutorial_done");
    e.preventDefault();
    alert("tutorial reset");
}


const getMilestoneViews = async (milestones) => {
    const views = [];

    for (const milestone of milestones) {
        let vmv;
        if (milestone.id.startsWith("vipmatch")) {
            vmv = await new VipMatchMilestoneView(milestone);
        } else if (milestone.id.startsWith("competence")) {
            vmv = await new CompetenceMilestoneView(milestone);
        } else if (milestone.id.startsWith("delegate-competence")) {
            vmv = await new DelegateCompetenceMilestoneView(milestone);
        }
        views.push(vmv);
    }

    return views;
}

const showMilestones = async (containerNavigator, max) => {
    const milestones = window.milestones;

    // await tutorial_step("milestones");

    let views = await getMilestoneViews(milestones);
    if (window.preview) {
        views = shuffleArray(views);
    }

    for (const view of views) {
        if (max && max <= 0) {
            // return;
        }
        containerNavigator.add2Views(view);
        if (max) max--;
    }
}

document.addEventListener("milestone_progress", async (event) => {
    const milestones = event.detail.milestones;
    window.setTimeout(async () => {
        Object.assign(window.milestones, milestones);

        const views = await getMilestoneViews(milestones);
        const view = views[0];

        containerNavigator.refreshView(view.uri);

        if (true) {
            view.showDetails = false;
            const node = await Viewer.show(view);
            document.loaderMenu.appendChild(node);
            view.shown(node);
            window.setTimeout(async () => {
                await fadeOut(node);
                document.loaderMenu.removeChild(node);
            }, 3000);
        }
    }, 100);
})

const onPreview = async function (preview) {

    const topic = preview.data && preview.data.topic ? Object.assign({}, preview.data.topic) : undefined;
    const dialectic = preview.data && preview.data.dialectic ? Object.assign({}, preview.data.dialectic) : undefined;
    const dialectics = preview.data && preview.data.dialectics ? preview.data.dialectics.map(el => { return Object.assign({}, el) }) : undefined;
    const risk = preview.data && preview.data.risk ? Object.assign({}, preview.data.risk) : undefined;


    if (preview.mode == "INTRO") {

        let competences;

        // await Navigator.add2Views(getUserView("Gemini@"+ SYSTEM_USER_DOMAIN));
        // await Navigator.add2Views(getUserView("Grok@"+ SYSTEM_USER_DOMAIN));
        // await Navigator.add2Views(getUserView("ChatGPT@"+ SYSTEM_USER_DOMAIN));

        if (topic) {
            await containerNavigator.add2Views(containerNavigator.createView(topic.uri, topic));
            for (let i = 0; dialectics; i++) {
                const dialectic = dialectics[i];
                await containerNavigator.add2Views(containerNavigator.createView(dialectic.uri, dialectic));
            }
        } else {
            const feedView = new FeedView();
            await feedView.load();
            competences = feedView.competenceView;
            window.feedCompetences = await feedView.competenceView.load();
            // await Navigator.add2Views(feedView);
            // await containerNavigator.add2Views(new HomeView());
            // await containerNavigator.add2Views(new FeedView());
            // await Navigator.add2Views(new FeedView());
        }

        await showMilestones(containerNavigator);

        await containerNavigator.add2Views(competences);

        // await containerNavigator.add2Views(getAlignmentView(["Gemini@"+ SYSTEM_USER_DOMAIN, "Grok@"+ SYSTEM_USER_DOMAIN, "ChatGPT@"+ SYSTEM_USER_DOMAIN], "What is the best AI for you?"));

        await containerNavigator.add2Views(getAlignmentView([], "Is AI the better leader?"));
        await containerNavigator.add2Views(getEyeView("/media/pdoom.jpg", "/media/logo3.svg"));
        await containerNavigator.add2Views(getAlignmentView([], "Can you help your people to stay in control?"));

        return false;
    } else if (preview.mode == "TOPIC") {
        topic.previewDialectics = [];
        await containerNavigator.add2Views(await loadTopicView(topic));
        return false;
    } else if (preview.mode == "CHART") {
        topic.previewDialectics = [dialectic];
        await containerNavigator.add2Views(getPreView(topic, preview.mode));
        return false;
    } else if (preview.mode == "VOTES" || preview.mode == "POLL_RESULT") {
        await prepareDialectic(dialectic);
        await showView(dialectic);
        return false;
    } else if (preview.mode == "POLL") {
        delete dialectic.userVoted;
        dialectic.statements.map(statement => {
            delete statement.percentVotes;
            statement.votes = [];
            statement.voted = 0;
        });
        delete dialectic.userDelegated;
        await prepareDialectic(dialectic);
        await showView(dialectic);
        return false;
    } else if (preview.mode == "RISK") {
        await showView(new RiskView(risk));
        return false;
    } else {
        console.error("unknown mode:", preview.mode);
        return true;
    }
}

if (!window.getPreviewObject) {
    window.getPreviewObject = () => {
        return {
            mode: new URL(window.location.href).searchParams.get("mode")
        }
    }
}

const loadContent = async function (reload) {

    const url = new URL(window.location.href);

    const lastUri = null; // = sessionStorage.getItem("lastUri");

    try {
        if (window.location.hash || lastUri) {
            // onActive("projection", () => { }, true);
            if (!window.location.hash) {
                if (lastUri) {
                    console.log("restoring last uri:", lastUri);
                    // window.location.hash = lastUri;
                }
            } else {
                if (await containerNavigator.onHashChange()) {
                    return;
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    if (!reload && document.contentLoaded) {
        console.log("content already loaded");
        return;
    }

    window.loadingMutex = window.loadingMutex || new Mutex();


    window.loadingMutex.runExclusive(async () => {

        try {
            let view;

            const uri = url.searchParams.get("uri");

            if (window.preview) {

                if (window.getPreviewObject) {
                    const preview = await window.getPreviewObject();
                    console.log("preview:", preview);
                    const continueAfterPreview = await onPreview(preview);
                    if (!continueAfterPreview) {
                        return;
                    };
                }
            }

            if (uri) {
                view = await containerNavigator.createView(uri);
                view.byUrlDirect = true;
                await containerNavigator.add2Views(view);
            } else {
                await showView(new FeedView());
            }

            document.contentLoaded = true;

        } catch (e) {
            console.error(e);
        }

    });


}

const cloneNode = (clone, elementToReplace) => {
    const cloneme = document.templates.querySelector(`[cloneme="${clone}"]`).cloneNode(true);
    cloneme.removeAttribute("cloneme");
    cloneme.classList.add(clone);
    // el.replaceWith(cloneme.cloneNode(true));
    if (elementToReplace) {
        elementToReplace.parentNode.insertBefore(cloneme, elementToReplace);
        elementToReplace.parentNode.removeChild(elementToReplace);
    }
    return cloneme;
}

var containerNavigator;

const onload = async function () {

    // Viewer.blockUi();

    document.templates = document.querySelector("#templates");

    await asyncForEach(document.querySelectorAll("include"), (el => {
        const clone = el.getAttribute("clone");
        cloneNode(clone, el);
    }));

    document.mainContent = document.querySelector("#content");
    containerNavigator = new Navigator(document.mainContent);
    if (useSplitContent()) {
        window.leftNavigator = new Navigator(document.querySelector("#left-content"));
        window.rightNavigator = new Navigator(document.querySelector("#right-content"));
    }
    window.containerNavigator = containerNavigator;

    document.interaction_menu = document.querySelector("#interaction_menu");

    document.tabs = document.interaction_menu.querySelector("#tabs");
    document.loaderMenu = document.interaction_menu.querySelector(".loaderMenu");
    document.loader = document.loaderMenu.querySelector("#mainLoader .loader");

    // document.addEventListener("scrollend", (event) => {
    //     console.log(`Document scrollend event fired!`);
    // });

    // registerServiceWorker();
    // registerUserFCM();
    initMenu();

    var resizing;
    window.onresize = function () {
        clearTimeout(resizing);
        resizing = setTimeout(async () => {
            initMyTabs();
            if (window.onResized) {
                window.onResized();
            }
        }, 333);
    };

    if (window.preview) {
        document.body.classList.add("preview");
        Viewer.oneView = true;

        document.addEventListener('view-shown', (e) => {
            // console.log("view shown", e);
            const ce = {
                viewsLoaded: containerNavigator.views.length,
                uri: e.detail.view.uri,
                viewIndex: e.detail.view.viewIndex,
                // data: e.detail.view.loaded
            };
            if (window.onViewEvent) {
                window.setTimeout(() => {
                    window.onViewEvent(ce);
                }, 666);
            }
        });




        document.mainContent.addEventListener("click", (e) => {
            containerNavigator.next();
        }, false);

        window.gtag = function () { }

        document.querySelector('#content .bottom-of-content').style.display = "none";

    } else {

        var STORAGE_KEY = 'bid';
        let bid = localStorage.getItem(STORAGE_KEY);
        if (!bid) {
            bid = window.crypto.randomUUID();
            localStorage.setItem(STORAGE_KEY, bid);
        }

        window.dataLayer = window.dataLayer || [];
        window.gtag = function () { dataLayer.push(arguments); }

        gtag('js', new Date());
        gtag('config', 'G-G8P11CZMVZ', {
            'send_page_view': false,
        });
        gtag('set', 'client_id', bid);

        let referrer = document.referrer;
        if (referrer && !referrer.includes(window.location.hostname)) {
            addReferrer(referrer);
        }

        // define an observer instance
        var observer = new IntersectionObserver(onIntersection, {
            // root: document.mainContent,   // default is the viewport
            threshold: .45 // percentage of target's visible area. Triggers "onIntersection"
        })

        // Use the observer to observe an element
        observer.observe(document.querySelector('#content .bottom-of-content'))
    }


    initMyTabs()

    try {
        const tutorial_done = getStoredJSONObject("tutorial_done", localStorage, null);
        if (!tutorial_done || !tutorial_done.allDone) {
            window.tutorial = fetchStatic("/tutorial.html").then(tutorial => {
                tutorial = templates.appendChild(tutorial.querySelector("#tutorial_templates"));
                // window.tutorial = tutorial;
                tutorial.isDone = function (step) {
                    return getStoredJSONObject("tutorial_done", localStorage)[step];
                }
                tutorial.steps = [];

                tutorial.querySelectorAll(".tutorial_step").forEach(step => {
                    const step_name = step.getAttribute("id");
                    tutorial.steps.push(step_name);
                });

                tutorial.done = function (step) {
                    const tutorial_done = getStoredJSONObject("tutorial_done", localStorage);

                    tutorial_done[step] = true;

                    const allDone = function () {
                        for (let i = 0; i < tutorial.steps.length; i++) {
                            const step = tutorial.steps[i];
                            if (!tutorial_done[step]) {
                                return false;
                            }
                        };
                        return true;
                    }
                    tutorial_done.allDone = allDone();
                    setStoredJSON("tutorial_done", tutorial_done, localStorage);
                }

                return tutorial;
            })
        }

    } catch (e) {
        console.error(e);
    }



    if (!window.preview) {
        // await Navigator.add2Views(getEyeView("/media/pDoom.jpg", "/media/logo.svg"));
    }


    window.addEventListener("popstate", containerNavigator.onpopstate);



    // getMyUser();
    // }

    // Navigator.to(0);

    // registerServiceWorker();
    // registerUserFCM();

}

import(/* webpackPrefetch: true */ './fbc.js');

window.onAuthChanged = async function (user) {

    console.log("onAuthChanged", user, window.user);

    if (window.preview) {
        loadContent(true);
        return;
    }

    window.fb_user = user;
    if (user && localStorage.getItem('sessionToken')) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        window.user = null;

        if (user.isAnonymous) {
            document.body.setAttribute("loggedin", "false");
            needsLogin();
        } else {
            document.body.setAttribute("loggedin", "true");
            onLoggedIn();
        }

        const app_user = await getMyUser();
        app_user.isAnonymous = true;
        console.log(app_user);

        loadContent(true);
        // ...
    } else {
        // User is signed out
        // ...
        console.log("signed out");
        setStoredJSON("user", null);

        getMyUser();
        needsLogin();

        const user = getStoredJSON("user");
        if (!user) {
            window.signInAnonymously();
        }
    }
}

window.setTimeout(() => {
    if (document.contentLoaded) {
        console.log("content already loaded before timeout");
        return;
    }
    // document.body.setAttribute("loggedin", "false");
    // needsLogin();
    loadContent();
}, 2100);

window.onloaded = (listener) => {
    if (document.readyState !== 'loading') {
        console.log('document is already ready, just execute code here');
        listener();
    } else {
        document.addEventListener('DOMContentLoaded', listener);
    }
}

window.onloaded(onload);